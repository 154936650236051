/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-unpublished-modal.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/i18n/i18n.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../node_modules/@bewatec-berlin/gentics-ui-core/bewatec-berlin-gentics-ui-core.ngfactory";
import * as i5 from "@bewatec-berlin/gentics-ui-core";
import * as i6 from "./confirm-unpublished-modal.component";
import * as i7 from "../../../core/providers/i18n/i18n.service";
import * as i8 from "../../providers/editor-effects.service";
var styles_ConfirmUnpublishedModalComponent = [i0.styles];
var RenderType_ConfirmUnpublishedModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmUnpublishedModalComponent, data: {} });
export { RenderType_ConfirmUnpublishedModalComponent as RenderType_ConfirmUnpublishedModalComponent };
export function View_ConfirmUnpublishedModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "form-confirm-unpublished"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.I18nPipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(14, 0, null, null, 14, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(16, 0, null, null, 11, "div", [["class", "hint"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "gtx-button", [["flat", ""], ["type", "secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).preventDisabledClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeFn(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_Button_0, i4.RenderType_Button)), i1.ɵdid(19, 49152, null, 0, i5.Button, [], { type: [0, "type"], flat: [1, "flat"] }, null), (_l()(), i1.ɵted(20, 0, ["\n                ", "\n            "])), i1.ɵpid(131072, i2.I18nPipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "gtx-button", [["type", "primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).preventDisabledClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.saveAndClose() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_Button_0, i4.RenderType_Button)), i1.ɵdid(24, 49152, null, 0, i5.Button, [], { type: [0, "type"] }, null), (_l()(), i1.ɵted(25, 0, ["\n                ", "\n            "])), i1.ɵpid(131072, i2.I18nPipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_2 = "secondary"; var currVal_3 = ""; _ck(_v, 19, 0, currVal_2, currVal_3); var currVal_5 = "primary"; _ck(_v, 24, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("modal.confirm_unpublished_title")); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 11, 0, currVal_1); var currVal_4 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("common.cancel_button")); _ck(_v, 20, 0, currVal_4); var currVal_6 = i1.ɵunv(_v, 25, 0, i1.ɵnov(_v, 26).transform("modal.continue_button")); _ck(_v, 25, 0, currVal_6); }); }
export function View_ConfirmUnpublishedModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mesh-confirm-unpublished-modal", [], null, null, null, View_ConfirmUnpublishedModalComponent_0, RenderType_ConfirmUnpublishedModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.ConfirmUnpublishedModalComponent, [i7.I18nService, i8.EditorEffectsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmUnpublishedModalComponentNgFactory = i1.ɵccf("mesh-confirm-unpublished-modal", i6.ConfirmUnpublishedModalComponent, View_ConfirmUnpublishedModalComponent_Host_0, {}, {}, []);
export { ConfirmUnpublishedModalComponentNgFactory as ConfirmUnpublishedModalComponentNgFactory };
