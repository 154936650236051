import * as tslib_1 from "tslib";
import { CloneDepth, Immutable, StateActionBranch } from 'immutablets';
import { mergeEntityState } from './entity-state-actions';
let AdminSchemasStateActions = class AdminSchemasStateActions extends StateActionBranch {
    constructor() {
        super({
            uses: ['adminSchemas', 'entities'],
            initialState: {
                adminSchemas: {
                    loadCount: 0,
                    assignedToProject: {},
                    schemaList: [],
                    schemaDetail: null,
                    microschemaList: [],
                    microschemaDetail: null,
                    pagination: {
                        currentPage: 1,
                        itemsPerPage: 25,
                        totalItems: null
                    },
                    filterTerm: ''
                }
            }
        });
    }
    setSchemaListPagination(currentPage, itemsPerPage) {
        this.adminSchemas.pagination = {
            currentPage,
            itemsPerPage,
            totalItems: null
        };
    }
    setFilterTerm(term) {
        this.adminSchemas.filterTerm = term;
    }
    fetchSchemasStart() {
        this.adminSchemas.loadCount++;
    }
    fetchSchemasSuccess(schemas) {
        this.adminSchemas.loadCount--;
        this.adminSchemas.schemaList = schemas.map(schema => schema.uuid);
        this.entities = mergeEntityState(this.entities, { schema: schemas });
    }
    fetchSchemasError() {
        this.adminSchemas.loadCount--;
    }
    fetchMicroschemasStart() {
        this.adminSchemas.loadCount++;
    }
    fetchMicroschemasSuccess(microschemas) {
        this.adminSchemas.loadCount--;
        this.adminSchemas.microschemaList = microschemas.map(schema => schema.uuid);
        this.entities = mergeEntityState(this.entities, {
            microschema: microschemas
        });
    }
    fetchMicroschemasError() {
        this.adminSchemas.loadCount--;
    }
    deleteMicroschemaStart() {
        this.adminSchemas.loadCount++;
    }
    deleteMicroschemaSuccess(microschemaUuid) {
        this.adminSchemas.loadCount--;
        this.adminSchemas.microschemaList = this.adminSchemas.microschemaList.filter(uuid => uuid !== microschemaUuid);
        this.entities.microschema = Object.keys(this.entities.microschema)
            .filter(uuid => uuid !== microschemaUuid)
            .reduce((all, current) => (Object.assign({}, all, this.entities.microschema[current])), {});
    }
    deleteMicroschemaError() {
        this.adminSchemas.loadCount--;
    }
    deleteSchemaStart() {
        this.adminSchemas.loadCount++;
    }
    deleteSchemaSuccess(schemaUuid) {
        this.adminSchemas.loadCount--;
        this.adminSchemas.schemaList = this.adminSchemas.schemaList.filter(uuid => uuid !== schemaUuid);
        this.entities.schema = Object.keys(this.entities.schema)
            .filter(uuid => uuid !== schemaUuid)
            .reduce((all, current) => (Object.assign({}, all, this.entities.schema[current])), {});
    }
    deleteSchemaError() {
        this.adminSchemas.loadCount--;
    }
    updateMicroschemaStart() {
        this.adminSchemas.loadCount++;
    }
    updateMicroschemaSuccess(response) {
        this.adminSchemas.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            microschema: [response]
        });
    }
    updateMicroschemaError() {
        this.adminSchemas.loadCount--;
    }
    createMicroschemaStart() {
        this.adminSchemas.loadCount++;
    }
    createMicroschemaSuccess(response) {
        this.adminSchemas.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            microschema: [response]
        });
        this.adminSchemas.microschemaList = [...this.adminSchemas.microschemaList, response.uuid];
    }
    createMicroschemaError() {
        this.adminSchemas.loadCount--;
    }
    openMicroschemaStart() {
        this.adminSchemas.loadCount++;
        this.adminSchemas.schemaDetail = null;
    }
    openMicroschemaSuccess(microschema) {
        this.adminSchemas.loadCount--;
        this.adminSchemas.microschemaDetail = microschema.uuid;
        this.entities = mergeEntityState(this.entities, {
            microschema: [microschema]
        });
    }
    openMicroschemaError() {
        this.adminSchemas.loadCount--;
    }
    newMicroschema() {
        this.adminSchemas.microschemaDetail = null;
    }
    updateSchemaStart() {
        this.adminSchemas.loadCount++;
    }
    updateSchemaSuccess(response) {
        this.adminSchemas.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            schema: [response]
        });
    }
    updateSchemaError() {
        this.adminSchemas.loadCount--;
    }
    createSchemaStart() {
        this.adminSchemas.loadCount++;
    }
    createSchemaSuccess(response) {
        this.adminSchemas.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            schema: [response]
        });
        this.adminSchemas.schemaList = [...this.adminSchemas.schemaList, response.uuid];
    }
    createSchemaError() {
        this.adminSchemas.loadCount--;
    }
    openSchemaStart() {
        this.adminSchemas.loadCount++;
        this.adminSchemas.schemaDetail = null;
    }
    openSchemaSuccess(schema) {
        this.adminSchemas.loadCount--;
        this.adminSchemas.schemaDetail = schema.uuid;
        this.entities = mergeEntityState(this.entities, {
            schema: [schema]
        });
    }
    openSchemaError() {
        this.adminSchemas.loadCount--;
    }
    newSchema() {
        this.adminSchemas.schemaDetail = null;
    }
    fetchEntityAssignmentsStart() {
        this.adminSchemas.loadCount++;
    }
    fetchEntityAssignmentProjectsSuccess(projects) {
        this.entities = mergeEntityState(this.entities, {
            project: projects
        });
    }
    fetchEntityAssignmentsSuccess(assignments) {
        this.adminSchemas.loadCount--;
        this.adminSchemas.assignedToProject = assignments;
    }
    fetchEntityAssignmentsError() {
        this.adminSchemas.loadCount--;
    }
    assignEntityToProjectStart() {
        this.adminSchemas.loadCount++;
    }
    assignEntityToProjectSuccess() {
        this.adminSchemas.loadCount--;
    }
    assignEntityToProjectError() {
        this.adminSchemas.loadCount--;
    }
    removeEntityFromProjectStart() {
        this.adminSchemas.loadCount++;
    }
    removeEntityFromProjectSuccess() {
        this.adminSchemas.loadCount--;
    }
    removeEntityFromProjectError() {
        this.adminSchemas.loadCount--;
    }
};
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], AdminSchemasStateActions.prototype, "adminSchemas", void 0);
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], AdminSchemasStateActions.prototype, "entities", void 0);
AdminSchemasStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [])
], AdminSchemasStateActions);
export { AdminSchemasStateActions };
