import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { MeshNode } from '../../../common/models/node.model';
import { PublishStatusModelFromServer } from '../../../common/models/server-models';
import { getNodeHasBeenEdited, getNodeHasBeenPartiallyEdited, getNodeStatus } from '../../../common/util/node-util';
import { UILanguage } from '../../../core/providers/i18n/i18n.service';
import { ApplicationStateService } from '../../../state/providers/application-state.service';

@Component({
    selector: 'mesh-node-status',
    templateUrl: './node-status.component.html',
    styleUrls: ['./node-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('animNgIfation', [
            transition(':enter', [style({ opacity: 0 }), animate('0.09s', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('0.09s', style({ opacity: 0 }))])
        ])
    ]
})
export class NodeStatusComponent implements OnChanges, OnDestroy {
    /** Node the status tag is indicating */
    @Input() node: MeshNode;

    /** Current node language */
    @Input() current: string;

    /** Displayed node title */
    nodeLabel: string;
    startDate: Date;
    endDate: Date;

    /** Published state of node depending on language */
    nodeIsPublished: boolean;

    /** Scheduling state of node */
    nodeIsScheduled: boolean;

    /** Current UI language */
    currentUiLanguage$: Observable<UILanguage>;

    /** Current node language */
    currentNodeLanguage$: Observable<string>;

    /** Date the node has been published depending on language */
    nodePublishDate: string | undefined;

    /** Date the node has been edited */
    nodeEditedDate: string | undefined;

    /** Span css class indicating status */
    cssClass: string;
    cssScheduledClass: string;

    /** True if mouse hover over div.status */
    hasHover = new BehaviorSubject<boolean>(false);
    schededuledHasHover = new BehaviorSubject<boolean>(false);

    /** Date format string */
    dateTimeFormat: {
        [key in UILanguage]: {
            date: string;
            time: string;
        };
    } = {
        en: {
            date: 'yyyy-MM-dd',
            time: 'HH:mm'
        },
        de: {
            date: 'dd.MM.yyyy',
            time: 'HH:mm'
        },
        zh: {
            date: 'yyyy-MM-dd',
            time: 'HH:mm'
        },
        pt: {
            date: 'dd-MM-yyyy',
            time: 'HH:mm'
        },
        hu: {
            date: 'yyyy.MM.dd.',
            time: 'HH:mm'
        }
    };

    // Format date string for date pipe
    dateFormat: string;

    // Format time string for date pipe
    timeFormat: string;

    // enum of node status strings for use in template
    nodeStatusStringsEnum: typeof EMeshNodeStatusStrings = EMeshNodeStatusStrings;

    /** Component lifecycle indicator */
    private destroy$ = new Subject<void>();

    constructor(private state: ApplicationStateService) {
        // get current UI language from state
        this.currentUiLanguage$ = this.state.select(state => state.ui.currentLanguage);
    }

    ngOnChanges(): void {
        this.setNodeStatus();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getDateTimeFormat(): Observable<{
        date: string;
        time: string;
    }> {
        return this.currentUiLanguage$.pipe(
            map((currentUiLanguage: UILanguage) => {
                return this.dateTimeFormat[currentUiLanguage];
            })
        );
    }

    onMouseenter(): void {
        this.hasHover.next(true);
    }

    onMouseleave() {
        setTimeout(() => {
            this.hasHover.next(false);
        }, 300);
    }

    onMouseHover(): void {
        this.schededuledHasHover.next(true);
    }

    onMouseHoverTimed() {
        setTimeout(() => {
            this.schededuledHasHover.next(false);
        }, 300);
    }
    getNodeHasStatus(): boolean {
        return !!this.node.availableLanguages && !!this.node.availableLanguages[this.current];
    }

    setNodeStatus(): void {
        const nodeStatus: EMeshNodeStatusStrings | null = getNodeStatus(this.node, this.current)[0];
        if (nodeStatus === null) {
            return;
        }

        const nodeStatusInformation: PublishStatusModelFromServer = this.node.availableLanguages[this.current];

        this.nodeIsPublished = nodeStatusInformation.published;
        this.nodePublishDate = nodeStatusInformation.publishDate;
        this.nodeIsScheduled = !!this.node.fields.nodeUpdateSchedule;
        this.nodeEditedDate = this.node.edited;

        if (this.nodeIsScheduled) {
            const { startDate, endDate } = JSON.parse(this.node.fields.nodeUpdateSchedule);
            if (startDate) {
                this.startDate = new Date(startDate * 1000);
            }
            if (endDate) {
                this.endDate = new Date(endDate * 1000);
            }
            this.cssScheduledClass = EMeshNodeStatusStrings.SCHEDULED;
        }
        // DRAFT
        if (!this.nodeIsPublished && getNodeHasBeenEdited(this.node)) {
            this.nodeLabel = EMeshNodeStatusStrings.DRAFT;
            this.cssClass = EMeshNodeStatusStrings.DRAFT;
            // PUBLISHED
        } else if (this.nodeIsPublished && !getNodeHasBeenEdited(this.node)) {
            this.nodeLabel = EMeshNodeStatusStrings.PUBLISHED;
            this.cssClass = EMeshNodeStatusStrings.PUBLISHED;

            // UPDATED
        } else if (this.nodeIsPublished && getNodeHasBeenEdited(this.node)) {
            this.nodeLabel = EMeshNodeStatusStrings.UPDATED;
            this.cssClass = EMeshNodeStatusStrings.UPDATED;

            // ARCHIVED
        } else if (!this.nodeIsPublished && !getNodeHasBeenEdited(this.node)) {
            this.nodeLabel = EMeshNodeStatusStrings.ARCHIVED;
            this.cssClass = EMeshNodeStatusStrings.ARCHIVED;
        }

        if (getNodeHasBeenPartiallyEdited(this.node) && this.nodeIsScheduled) {
            this.nodeLabel = EMeshNodeStatusStrings.ARCHIVED;
            this.cssClass = EMeshNodeStatusStrings.ARCHIVED;
        }
        if (getNodeHasBeenPartiallyEdited(this.node) && this.nodeIsScheduled && this.nodeIsPublished) {
            this.nodeLabel = EMeshNodeStatusStrings.UPDATED;
            this.cssClass = EMeshNodeStatusStrings.UPDATED;
        }
    }
}
// possible node states
export enum EMeshNodeStatusStrings {
    DRAFT = 'draft',
    PUBLISHED = 'published',
    UPDATED = 'updated',
    ARCHIVED = 'archived',
    SCHEDULED = 'scheduled'
}
