export const ADDED_INTERVAL_IN_MINUTES = 20;
export const ADDED_START_INTERVAL_IN_MINUTES = 10;
export const ADMIN_USER_NAME = 'admin';
export const ADMIN_GROUP_NAME = 'admin';
export const BREADCRUMBS_BAR_PORTAL_ID = 'breadcrumbs-bar';
export const DEFAULT_ITEMS_PER_PAGE = 20;
export const QUERY_KEY_KEYWORD = 'q';
export const QUERY_KEY_TAGS = 't';
export const QUERY_KEY_NODE_STATUS_FILTER = 'n';
export const QUERY_KEY_PAGE = 'p';
export const QUERY_KEY_PERPAGE = 'perPage';
export const LOCAL_STORAGE_LANG_KEY = 'meshui_current_lang';
