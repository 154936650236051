/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icon-checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@bewatec-berlin/gentics-ui-core/bewatec-berlin-gentics-ui-core.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@bewatec-berlin/gentics-ui-core";
import * as i5 from "@angular/common";
import * as i6 from "./icon-checkbox.component";
var styles_IconCheckboxComponent = [i0.styles];
var RenderType_IconCheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IconCheckboxComponent, data: {} });
export { RenderType_IconCheckboxComponent as RenderType_IconCheckboxComponent };
function View_IconCheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "item-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "gtx-checkbox", [], null, [[null, "change"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).focusHandler($event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.checkboxClicked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_Checkbox_0, i2.RenderType_Checkbox)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.Checkbox]), i1.ɵdid(4, 4308992, null, 0, i4.Checkbox, [i1.ChangeDetectorRef], { checked: [0, "checked"] }, { change: "change" }), (_l()(), i1.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selected; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_IconCheckboxComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "icon-checkbox-wrapper"]], [[2, "checked", null], [2, "disabled", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "item-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IconCheckboxComponent_1)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.disabled; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selected; var currVal_1 = _co.disabled; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.icon; _ck(_v, 5, 0, currVal_2); }); }
export function View_IconCheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mesh-icon-checkbox", [], null, null, null, View_IconCheckboxComponent_0, RenderType_IconCheckboxComponent)), i1.ɵdid(1, 49152, null, 0, i6.IconCheckboxComponent, [], null, null)], null, null); }
var IconCheckboxComponentNgFactory = i1.ɵccf("mesh-icon-checkbox", i6.IconCheckboxComponent, View_IconCheckboxComponent_Host_0, { selected: "selected", disabled: "disabled", icon: "icon" }, { change: "change" }, []);
export { IconCheckboxComponentNgFactory as IconCheckboxComponentNgFactory };
