import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { AuthGuard } from './core/providers/guards/auth-guard';
const ɵ0 = () => import("./login/login.module.ngfactory").then(m => m.LoginModuleNgFactory), ɵ1 = () => import("./editor/editor.module.ngfactory").then(m => m.EditorModuleNgFactory), ɵ2 = () => import("./admin/admin.module.ngfactory").then(m => m.AdminModuleNgFactory);
export const ROUTER_CONFIG = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', loadChildren: ɵ0 },
    { path: 'not-found', component: NotFoundComponent },
    {
        path: '',
        children: [
            {
                path: 'editor',
                canActivate: [AuthGuard],
                loadChildren: ɵ1
            },
            {
                path: 'admin',
                canActivate: [AuthGuard],
                loadChildren: ɵ2
            }
        ]
    },
    { path: '**', redirectTo: 'not-found', pathMatch: 'full' }
];
export { ɵ0, ɵ1, ɵ2 };
