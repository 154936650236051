import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { GraphQLRequest } from 'src/app/common/models/server-models';
import { extractGraphQlResponse } from 'src/app/common/util/util';
import { AdminApi } from './admin-api.class';
import { ApiBase } from './api-base.service';
import { apiPost } from './api-methods';
import { AuthApi } from './auth-api.class';
import { ProjectApi } from './project-api.class';
import { SearchApi } from './search-api.service';
import { UserApi } from './user-api.class';
export class ApiService {
    constructor(apiBase) {
        this.apiBase = apiBase;
        this.graphQL = apiPost('/{project}/graphql');
        this.auth = new AuthApi(apiBase);
        this.project = new ProjectApi(apiBase);
        this.user = new UserApi(apiBase);
        this.admin = new AdminApi(apiBase);
        this.search = new SearchApi(apiBase);
    }
    setLanguageForServerMessages(language) {
        this.apiBase.setLanguageForServerMessages(language);
    }
    formatGraphQLSearchQuery(query) {
        return JSON.stringify(JSON.stringify(query));
    }
    /**
     * Loads a random existing project.
     * This is necessary to query data by GraphQL.
     * Remove this after https://github.com/gentics/mesh/issues/504 is resolved
     */
    getAnyProjectName() {
        return this.apiBase
            .get('/projects', { perPage: 1, fields: 'name' })
            .pipe(map(projects => projects.data[0].name))
            .toPromise();
    }
    graphQLInAnyProject(request) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.graphQL({ project: yield this.getAnyProjectName() }, request)
                .pipe(map(extractGraphQlResponse))
                .toPromise();
        });
    }
}
