/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@bewatec-berlin/gentics-ui-core/bewatec-berlin-gentics-ui-core.ngfactory";
import * as i2 from "@bewatec-berlin/gentics-ui-core";
import * as i3 from "../../../shared/pipes/i18n/i18n.pipe";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./user-dropdown.component";
import * as i6 from "../../../state/providers/application-state.service";
import * as i7 from "../../../login/providers/auth-effects.service";
var styles_UserDropdownComponent = [];
var RenderType_UserDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserDropdownComponent, data: {} });
export { RenderType_UserDropdownComponent as RenderType_UserDropdownComponent };
export function View_UserDropdownComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "gtx-button", [["flat", ""], ["size", "regular"], ["type", "secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).preventDisabledClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.changePassword() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_Button_0, i1.RenderType_Button)), i0.ɵdid(3, 49152, null, 0, i2.Button, [], { size: [0, "size"], type: [1, "type"], flat: [2, "flat"] }, null), (_l()(), i0.ɵted(4, 0, ["\n        ", "\n    "])), i0.ɵpid(131072, i3.I18nPipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(8, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "gtx-button", [["flat", ""], ["size", "regular"], ["type", "secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).preventDisabledClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.logOut() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_Button_0, i1.RenderType_Button)), i0.ɵdid(11, 49152, null, 0, i2.Button, [], { size: [0, "size"], type: [1, "type"], flat: [2, "flat"] }, null), (_l()(), i0.ɵted(12, 0, ["\n        ", "\n    "])), i0.ɵpid(131072, i3.I18nPipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "regular"; var currVal_1 = "secondary"; var currVal_2 = ""; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = "regular"; var currVal_5 = "secondary"; var currVal_6 = ""; _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_3 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("auth.change_password")); _ck(_v, 4, 0, currVal_3); var currVal_7 = i0.ɵunv(_v, 12, 0, i0.ɵnov(_v, 13).transform("auth.logout")); _ck(_v, 12, 0, currVal_7); }); }
export function View_UserDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mesh-user-dropdown", [], null, null, null, View_UserDropdownComponent_0, RenderType_UserDropdownComponent)), i0.ɵdid(1, 114688, null, 0, i5.UserDropdownComponent, [i6.ApplicationStateService, i2.ModalService, i7.AuthEffectsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserDropdownComponentNgFactory = i0.ɵccf("mesh-user-dropdown", i5.UserDropdownComponent, View_UserDropdownComponent_Host_0, {}, {}, []);
export { UserDropdownComponentNgFactory as UserDropdownComponentNgFactory };
