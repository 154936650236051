<ng-container *ngFor="let item of items">
    <gtx-contents-list-item class="icon-checkbox-trigger">
        <mesh-icon-checkbox *ngIf="selectable(item) && item.isContainer"
                            icon="folder"
                            [selected]="!!selection[item.uuid]"
                            (change)="handleSelect(item, $event)">
        </mesh-icon-checkbox>

        <mesh-icon-checkbox *ngIf="selectable(item) && !item.isContainer"
                            icon="insert_drive_file"
                            [selected]="!!selection[item.uuid]"
                            (change)="handleSelect(item, $event)">
        </mesh-icon-checkbox>

        <div *ngIf="!selectable(item)"
             class="icons">
            <icon *ngIf="item.isContainer">folder</icon>
            <icon *ngIf="!item.isContainer">insert_drive_file</icon>
        </div>

        <div class="item-primary displayName">
            <a *ngIf="item.isContainer"
               (click)="containerClicked.emit(item)"
               class="node-browser-container">
                {{item.displayName || item.uuid}}
            </a>
            <ng-container *ngIf="!item.isContainer">{{item.displayName || item.uuid}}</ng-container>
            <div class="available-languages">
                <mesh-available-languages-list [node]="node"
                                               (nodeLanguage)="editNodeTranslation( $event )"
                                               [current]="currentLanguage$ | async">
                </mesh-available-languages-list>
            </div>
        </div>

        <div class="schemaName">
            <icon>view_quilt</icon>
            <div>{{item.schema.name}}</div>
        </div>
    </gtx-contents-list-item>
</ng-container>

<div class="no-results"
     *ngIf="items?.length === 0">
    {{ 'nodebrowser.no_children_found' | i18n }}
</div>
