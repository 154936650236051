import { EMeshNodeStatusStrings } from '../../shared/components/node-status/node-status.component';
import { MeshNode } from '../models/node.model';
import { PublishStatusModelFromServer } from '../models/server-models';

// Pure functions regarding mesh nodes.

/**
 * Tests if the specified language is published.
 */
export function languagePublished(node: MeshNode, language: string): boolean {
    if (!node.availableLanguages) {
        return false;
    }
    const lang = node.availableLanguages[language];
    return lang && node.version === lang.version && lang.published;
}

/**
 * Tests if the node in the current language is published.
 */
export function currentLanguagePublished(node: MeshNode): boolean {
    if (!node.language) {
        return false;
    }
    return languagePublished(node, node.language);
}

/**
 * Tests if the current language is published for all nodes.
 */
export function currentLanguageOfAllNodesPublished(nodes: MeshNode[]): boolean {
    return nodes.every(currentLanguagePublished);
}

/**
 * Tests if all languages are published.
 */
export function allLanguagesPublished(node: MeshNode): boolean {
    if (!node.availableLanguages) {
        return false;
    }
    return Object.keys(node.availableLanguages).every(language => node.availableLanguages[language].published);
}

/**
 * Tests if the current language is published for all nodes.
 */
export function allLanguagesOfAllNodesPublished(nodes: MeshNode[]): boolean {
    return nodes.every(allLanguagesPublished);
}

/**
 * Tests if the node in the current language is unpublished.
 */
export function currentLanguageUnpublished(node: MeshNode): boolean {
    return !currentLanguagePublished(node);
}

/**
 * Tests if the current language is unpublished for all nodes.
 */
export function currentLanguageOfAllNodesUnpublished(nodes: MeshNode[]): boolean {
    return nodes.every(currentLanguageUnpublished);
}

/**
 * Tests if all languages are unpublished.
 */
export function allLanguagesUnpublished(node: MeshNode): boolean {
    if (!node.availableLanguages) {
        return false;
    }
    return Object.keys(node.availableLanguages).every(language => !node.availableLanguages[language].published);
}

/**
 * Tests if the current language is unpublished for all nodes.
 */
export function allLanguagesOfAllNodesUnpublished(nodes: MeshNode[]): boolean {
    return nodes.every(allLanguagesUnpublished);
}

/**
 * Computes and returns the mesh node status of a given node and language.
 */
export function getNodeStatus(node: MeshNode, language: string): (EMeshNodeStatusStrings | null)[] | [] {
    const status = [];
    if (!node.availableLanguages || !node.availableLanguages[language]) {
        status.push(null);
        return status;
    }

    const nodeStatusInformation: PublishStatusModelFromServer = node.availableLanguages[language];

    // DRAFT
    if (!nodeStatusInformation.published && getNodeHasBeenEdited(node)) {
        status.push(EMeshNodeStatusStrings.DRAFT);

        // PUBLISHED
    } else if (nodeStatusInformation.published && !getNodeHasBeenEdited(node)) {
        status.push(EMeshNodeStatusStrings.PUBLISHED);

        // UPDATED
    } else if (nodeStatusInformation.published && getNodeHasBeenEdited(node)) {
        status.push(EMeshNodeStatusStrings.UPDATED);

        // ARCHIVED
    } else if (!nodeStatusInformation.published && !getNodeHasBeenEdited(node)) {
        status.push(EMeshNodeStatusStrings.ARCHIVED);
    } else {
        status.push(null);
    }

    // SCHEDULED
    if (nodeIsScheduled(node)) {
        status.push(EMeshNodeStatusStrings.SCHEDULED);
    }

    return status;
}

export function getNodeHasBeenEdited(node: MeshNode): boolean {
    return getNodeVersionParsed(node).versionMinor > 0;
}

export function getNodeHasBeenPartiallyEdited(node: MeshNode): boolean {
    return getNodeVersionParsed(node).versionMinor === 1;
}

function getNodeVersionParsed(node: MeshNode) {
    return {
        versionMajor: parseInt(node.version.split('.')[0], 10),
        versionMinor: parseInt(node.version.split('.')[1], 10)
    };
}

/**
 * Tests if node is Scheduled
 */
export function nodeIsScheduled(node: MeshNode): boolean {
    return !!node && !!node.fields && !!node.fields.nodeUpdateSchedule;
}
