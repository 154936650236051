<gtx-progress-bar [for]="passwordChanging$"></gtx-progress-bar>

<div class="modal-title">
    <h5>{{ 'modal.change_password_title' | i18n }}</h5>
</div>
<div class="modal-content">
    <form [formGroup]="form">
        <gtx-input [label]="'modal.new_password_label' | i18n" type="password" formControlName="password1"></gtx-input>
        <gtx-input [label]="'modal.confirm_password_label' | i18n" type="password" formControlName="password2"></gtx-input>
        <div class="validation-hints" *ngIf="password2?.dirty">
            <div *ngIf="form?.hasError('mustBeEqual')">
                {{ 'modal.validation_passwords_must_match' | i18n }}
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <gtx-button type="secondary" flat
                (click)="cancelFn()">{{ 'common.cancel_button' | i18n }}</gtx-button>
    <gtx-button type="primary"
                [disabled]="!form?.valid || (passwordChanging$ | async)"
                (click)="changePassword()">{{ 'modal.change_password_button' | i18n }}</gtx-button>
</div>
