import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalService } from '@bewatec-berlin/gentics-ui-core';
import * as NodeUtil from '../../../common/util/node-util';
import { ConfirmUnpublishedModalComponent } from '../../../editor/components/confirm-unpublished-modal/confirm-unpublished-modal.component';
import { EditorEffectsService } from '../../../editor/providers/editor-effects.service';
export class PublishOptionsComponent {
    constructor(editorEffects, modalService) {
        this.editorEffects = editorEffects;
        this.modalService = modalService;
        this.nodeUtil = NodeUtil;
    }
    ngOnInit() { }
    publishNode() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.beforePublish) {
                yield this.beforePublish();
            }
            this.editorEffects.publishNode(this.node);
        });
    }
    publishNodeLanguage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.beforePublish) {
                yield this.beforePublish();
            }
            this.editorEffects.publishNodeLanguage(this.node);
        });
    }
    unpublishNode() {
        if (!this.node.container) {
            this.editorEffects.unpublishNode(this.node);
        }
        else {
            const options = {
                closeOnOverlayClick: false
            };
            this.modalService
                .fromComponent(ConfirmUnpublishedModalComponent, options, { node: this.node })
                .then(modal => modal.open());
        }
    }
    unpublishNodeLanguage() {
        this.editorEffects.unpublishNodeLanguage(this.node);
    }
}
