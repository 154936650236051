<gtx-dropdown-list width="trigger">
    <gtx-dropdown-trigger>
        <gtx-button flat
                    size="regular"
                    type="secondary">
            {{ currentLanguage$ | async | i18n }}
            <icon right>arrow_drop_down</icon>
        </gtx-button>
    </gtx-dropdown-trigger>
    <gtx-dropdown-content>
        <gtx-dropdown-item *ngIf="availableLanguages.includes('de')"
                           (click)="changeLanguage('de')">
            Deutsch
        </gtx-dropdown-item>
        <gtx-dropdown-item *ngIf="availableLanguages.includes('en')"
                           (click)="changeLanguage('en')">
            English
        </gtx-dropdown-item>
        <gtx-dropdown-item *ngIf="availableLanguages.includes('zh')"
                           (click)="changeLanguage('zh')">
            Chinese
        </gtx-dropdown-item>
        <gtx-dropdown-item *ngIf="availableLanguages.includes('pt')"
                           (click)="changeLanguage('pt')">
            Português
        </gtx-dropdown-item>
        <gtx-dropdown-item *ngIf="availableLanguages.includes('hu')"
                           (click)="changeLanguage('hu')">
            Magyar
        </gtx-dropdown-item>
    </gtx-dropdown-content>
</gtx-dropdown-list>
