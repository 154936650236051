<li>
    <gtx-button flat
                size="regular"
                type="secondary"
                (click)="changePassword()">
        {{ 'auth.change_password' | i18n }}
    </gtx-button>
</li>
<li>
    <gtx-button flat
                size="regular"
                type="secondary"
                (click)="logOut()">
        {{ 'auth.logout' | i18n }}
    </gtx-button>
</li>
