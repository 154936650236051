import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ModalService } from '@bewatec-berlin/gentics-ui-core';
import { SchedulingModalComponent } from 'src/app/editor/components/scheduling-modal/scheduling-modal.component';

import { ADDED_INTERVAL_IN_MINUTES, ADDED_START_INTERVAL_IN_MINUTES } from '../../../common/constants';
import { MeshNode } from '../../../common/models/node.model';
import * as NodeUtil from '../../../common/util/node-util';
import * as ScheduleUtil from '../../../common/util/schedule-util';
import { ConfigService } from '../../../core/providers/config/config.service';
import { EditorEffectsService } from '../../../editor/providers/editor-effects.service';
import { EMeshNodeStatusStrings } from '../node-status/node-status.component';

@Component({
    selector: 'mesh-publish-scheduled-options',
    templateUrl: './publish-scheduled-options.component.html',
    styleUrls: ['./publish-scheduled-options.component.scss']
})
export class PublishScheduledOptionsComponent implements OnInit, OnChanges {
    @Input() node: MeshNode;

    nodeUtil = NodeUtil;
    scheduleUtil = ScheduleUtil;
    status: (EMeshNodeStatusStrings | null)[] | [];
    isUpdated = false;

    constructor(
        private editorEffects: EditorEffectsService,
        private modalService: ModalService,
        private config: ConfigService
    ) {}

    ngOnInit() {
        this.checkStatus();
    }

    ngOnChanges() {
        this.checkStatus();
    }

    async openSchedulingModal(startDateTimestamp?: number, endDateTimestamp?: number) {
        const isPublished = this.nodeUtil.currentLanguagePublished(this.node);
        if (isPublished || this.isUpdated) {
            if (endDateTimestamp === undefined) {
                endDateTimestamp = this.getTimestamp(ADDED_START_INTERVAL_IN_MINUTES + ADDED_INTERVAL_IN_MINUTES);
            }
        } else if (startDateTimestamp === undefined) {
            startDateTimestamp = this.getTimestamp(ADDED_START_INTERVAL_IN_MINUTES)
        }

        this.modalService
            .fromComponent(SchedulingModalComponent, {}, { node: this.node, startDateTimestamp, endDateTimestamp })
            .then(modal => modal.open());
    }

    private getTimestamp(intervalInMinutes: number = 0) {
        return Math.floor((Date.now() + intervalInMinutes * 60 * 1000) / 1000);
    }

    async openSchedulingModalDefault() {
        const now = new Date();
        this.openSchedulingModal(this.scheduleUtil.getNextMondayTimestamp(now));
    }

    async openSchedulingModalEdit() {
        const { startDate, endDate } = JSON.parse(this.node.fields.nodeUpdateSchedule);
        this.openSchedulingModal(startDate, endDate);
    }

    async clearSchedule() {
        this.scheduleUtil.clearNodeUpdateSchemaValue(this.node);
        const isPublished = this.nodeUtil.currentLanguagePublished(this.node);
        this.editorEffects.saveNode(this.node).then(() => {
            if (isPublished) {
                this.editorEffects.publishNode(this.node);
            }
        });
    }

    private checkStatus() {
        this.status = this.nodeUtil.getNodeStatus(this.node, this.node.language || this.config.DEFAULT_LANGUAGE);
        this.isUpdated = Array.from(this.status).includes(EMeshNodeStatusStrings.UPDATED);
    }
}
