/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./available-languages-list.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./available-languages-list.component";
var styles_AvailableLanguagesListComponent = [i0.styles];
var RenderType_AvailableLanguagesListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvailableLanguagesListComponent, data: {} });
export { RenderType_AvailableLanguagesListComponent as RenderType_AvailableLanguagesListComponent };
function View_AvailableLanguagesListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], [[2, "current", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickOnLanguage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === _co.current); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }); }
export function View_AvailableLanguagesListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvailableLanguagesListComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sortedLanguages; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AvailableLanguagesListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mesh-available-languages-list", [], null, null, null, View_AvailableLanguagesListComponent_0, RenderType_AvailableLanguagesListComponent)), i1.ɵdid(1, 573440, null, 0, i3.AvailableLanguagesListComponent, [], null, null)], null, null); }
var AvailableLanguagesListComponentNgFactory = i1.ɵccf("mesh-available-languages-list", i3.AvailableLanguagesListComponent, View_AvailableLanguagesListComponent_Host_0, { node: "node", current: "current" }, { nodeLanguage: "nodeLanguage" }, []);
export { AvailableLanguagesListComponentNgFactory as AvailableLanguagesListComponentNgFactory };
