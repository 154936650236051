// @ts-ignore
const config = require('../../../../../config.json');
import { API_BASE_URL } from './api-di-tokens';
const baseUrlFactory = () => {
    if (config.apiBaseUrl && config.apiBaseUrl !== '%GENTICS_MESH_API_BASE_URL%') {
        return config.apiBaseUrl;
    }
    return '/api/v1';
};
const ɵ0 = baseUrlFactory;
export const BASE_URL_PROVIDER = { provide: API_BASE_URL, useFactory: baseUrlFactory };
export { ɵ0 };
