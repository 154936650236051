<div class="form-confirm-unpublished">
    <div class="modal-title">
        <h3>{{ 'modal.confirm_unpublished_title' | i18n }}</h3>
    </div>
    <div class="modal-content">
        <p [innerHTML]="text"></p>
    </div>
    <div class="modal-footer">
        <div class="hint">
            <gtx-button type="secondary"
                        flat
                        (click)="closeFn(false)">
                {{ 'common.cancel_button' | i18n }}
            </gtx-button>
            <gtx-button type="primary"
                        (click)="saveAndClose()">
                {{ 'modal.continue_button' | i18n }}
            </gtx-button>
        </div>
    </div>
</div>
