/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@bewatec-berlin/gentics-ui-core";
import * as i3 from "@angular/common";
import * as i4 from "./chip.component";
var styles_ChipComponent = [i0.styles];
var RenderType_ChipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChipComponent, data: {} });
export { RenderType_ChipComponent as RenderType_ChipComponent };
function View_ChipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "icon", [["class", "remove-button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.Icon, [], null, null), (_l()(), i1.ɵted(-1, null, ["close"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.removeTitle; _ck(_v, 0, 0, currVal_0); }); }
function View_ChipComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "icon", [["class", "edit-button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.Icon, [], null, null), (_l()(), i1.ɵted(-1, null, ["edit"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editTitle; _ck(_v, 0, 0, currVal_0); }); }
export function View_ChipComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "search-chip"]], [[2, "removable", null], [2, "editable", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "chip-contents"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChipComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChipComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.removable; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.editable; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.removable; var currVal_1 = _co.editable; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ChipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mesh-chip", [], null, null, null, View_ChipComponent_0, RenderType_ChipComponent)), i1.ɵdid(1, 49152, null, 0, i4.ChipComponent, [], null, null)], null, null); }
var ChipComponentNgFactory = i1.ɵccf("mesh-chip", i4.ChipComponent, View_ChipComponent_Host_0, { removeTitle: "removeTitle", removable: "removable", editTitle: "editTitle", editable: "editable" }, { removeClick: "removeClick", editClick: "editClick" }, ["*"]);
export { ChipComponentNgFactory as ChipComponentNgFactory };
