import { DateTimePickerFormatProvider } from '@bewatec-berlin/gentics-ui-core';
export class DeFormatProviderDirective extends DateTimePickerFormatProvider {
    constructor() {
        super(...arguments);
        this.strings = {
            hours: 'Stunde',
            minutes: 'Minute',
            seconds: 'Sekunde',
            months: [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember'
            ],
            monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            cancel: 'Abbrechen',
            okay: 'Okay'
        };
    }
}
