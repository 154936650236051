import * as tslib_1 from "tslib";
import { CloneDepth, Immutable, StateActionBranch } from 'immutablets';
import { mergeEntityState } from './entity-state-actions';
let AdminProjectsStateActions = class AdminProjectsStateActions extends StateActionBranch {
    constructor() {
        super({
            uses: ['adminProjects', 'entities'],
            initialState: {
                adminProjects: {
                    loadCount: 0,
                    projectList: [],
                    projectDetail: null,
                    filterTerm: '',
                    filterTagsTerm: '',
                    filterTermSchemas: '',
                    filterTermMicroschema: ''
                }
            }
        });
    }
    fetchProjectsStart() {
        this.adminProjects.loadCount++;
    }
    fetchProjectsSuccess(projects) {
        this.adminProjects.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            project: projects
        });
        this.adminProjects.projectList = projects.map(project => project.uuid);
    }
    fetchProjectsError() {
        this.adminProjects.loadCount--;
    }
    createProjectStart() {
        this.adminProjects.loadCount++;
    }
    createProjectSuccess(project) {
        this.adminProjects.loadCount--;
        this.entities = mergeEntityState(this.entities, { project: [project] });
        this.adminProjects.projectList = [...this.adminProjects.projectList, project.uuid];
    }
    createProjectError() {
        this.adminProjects.loadCount--;
    }
    updateProjectStart() {
        this.adminProjects.loadCount++;
    }
    updateProjectSuccess(project) {
        this.adminProjects.loadCount--;
        this.entities = mergeEntityState(this.entities, { project: [project] });
    }
    updateProjectError() {
        this.adminProjects.loadCount--;
    }
    deleteProjectStart() {
        this.adminProjects.loadCount++;
    }
    deleteProjectSuccess(projectUuid) {
        this.adminProjects.projectList = this.adminProjects.projectList.filter(uuid => uuid !== projectUuid);
    }
    deleteProjectError() {
        this.adminProjects.loadCount--;
    }
    newProject() {
        this.adminProjects.projectDetail = null;
    }
    openProjectStart() {
        this.adminProjects.loadCount++;
        this.adminProjects.projectDetail = null;
    }
    openProjectSuccess(project) {
        this.adminProjects.loadCount--;
        this.adminProjects.projectDetail = project.uuid;
        this.entities = mergeEntityState(this.entities, {
            project: [project]
        });
    }
    openProjectError() {
        this.adminProjects.loadCount--;
    }
    setTagFilterTerm(term) {
        this.adminProjects.filterTagsTerm = term;
    }
    setFilterTerm(term) {
        this.adminProjects.filterTerm = term;
    }
    setFilterTermSchema(term) {
        this.adminProjects.filterTermSchemas = term;
    }
    setFilterTermMicroschema(term) {
        this.adminProjects.filterTermMicroschema = term;
    }
};
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], AdminProjectsStateActions.prototype, "adminProjects", void 0);
tslib_1.__decorate([
    CloneDepth(0),
    tslib_1.__metadata("design:type", Object)
], AdminProjectsStateActions.prototype, "entities", void 0);
AdminProjectsStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [])
], AdminProjectsStateActions);
export { AdminProjectsStateActions };
