import * as tslib_1 from "tslib";
import { withChanges, CloneDepth, Immutable, StateActionBranch } from 'immutablets';
import { ConfigService } from '../../core/providers/config/config.service';
import { mergeEntityState } from './entity-state-actions';
let EditorStateActions = class EditorStateActions extends StateActionBranch {
    constructor(config) {
        super({
            uses: ['editor', 'entities'],
            initialState: {
                editor: {
                    editorIsFocused: false,
                    editorIsOpen: false,
                    openNode: null,
                    loadCount: 0
                }
            }
        });
        this.config = config;
    }
    openNewNode(projectName, schemaUuid, parentNodeUuid, language) {
        this.editor = withChanges(this.editor, {
            openNode: {
                uuid: '',
                projectName,
                language,
                schemaUuid,
                parentNodeUuid
            },
            editorIsOpen: true,
            editorIsFocused: true
        });
    }
    openNode(projectName, uuid, language) {
        this.editor = withChanges(this.editor, {
            openNode: {
                uuid,
                projectName,
                language
            },
            editorIsOpen: true,
            editorIsFocused: true
        });
    }
    closeEditor() {
        this.editor.editorIsOpen = false;
        this.editor.editorIsFocused = false;
        this.editor.openNode = null;
    }
    focusEditor() {
        this.editor.editorIsFocused = true;
    }
    focusList() {
        this.editor.editorIsFocused = false;
    }
    saveNodeStart() {
        this.editor.loadCount++;
    }
    saveNodeError() {
        this.editor.loadCount--;
    }
    saveNodeSuccess(node) {
        this.editor.loadCount--;
        this.updateNodeEntity(node);
    }
    publishNodeStart() {
        this.editor.loadCount++;
    }
    publishNodeError() {
        this.editor.loadCount--;
    }
    publishNodeSuccess(node) {
        this.editor.loadCount--;
        if (!!node) {
            this.updateNodeEntity(node);
        }
    }
    publishNodesStart() {
        this.editor.loadCount++;
    }
    publishNodesError() {
        this.editor.loadCount--;
    }
    publishNodesSuccess() {
        this.editor.loadCount--;
    }
    unpublishNodeStart() {
        this.editor.loadCount++;
    }
    unpublishNodeError() {
        this.editor.loadCount--;
    }
    unpublishNodeSuccess(node) {
        this.editor.loadCount--;
        this.updateNodeEntity(node);
    }
    updateNodeEntity(node) {
        this.entities = mergeEntityState(this.entities, {
            node: [node]
        });
        const availableLanguages = node.availableLanguages;
        const languages = {};
        // Set the available languages of all contents of the node
        for (const language of Object.keys(this.entities.node[node.uuid])) {
            languages[language] = {};
            for (const version of Object.keys(this.entities.node[node.uuid][language])) {
                languages[language][version] = Object.assign({}, this.entities.node[node.uuid][language][version], { availableLanguages });
            }
        }
        this.entities = Object.assign({}, this.entities, { node: Object.assign({}, this.entities.node, { [node.uuid]: languages }) });
    }
};
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], EditorStateActions.prototype, "editor", void 0);
tslib_1.__decorate([
    CloneDepth(0),
    tslib_1.__metadata("design:type", Object)
], EditorStateActions.prototype, "entities", void 0);
EditorStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [ConfigService])
], EditorStateActions);
export { EditorStateActions };
