import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { of } from 'ramda';
import { forkJoin } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { getBinaryOrS3BinaryTypeMeshNodeFields, promiseConcat, simpleCloneDeep, stripNulls } from '../../common/util/util';
import { ApiService } from '../../core/providers/api/api.service';
import { ConfigService } from '../../core/providers/config/config.service';
import { I18nNotification } from '../../core/providers/i18n-notification/i18n-notification.service';
import { ApplicationStateService } from '../../state/providers/application-state.service';
import { EntitiesService } from '../../state/providers/entities.service';
export class EditorEffectsService {
    constructor(state, entities, notification, config, api, http) {
        this.state = state;
        this.entities = entities;
        this.notification = notification;
        this.config = config;
        this.api = api;
        this.http = http;
    }
    openNode(projectName, nodeUuid, language) {
        const lang = language || this.config.FALLBACK_LANGUAGE;
        this.state.actions.editor.openNode(projectName, nodeUuid, lang);
        this.loadNode(projectName, nodeUuid, language);
    }
    loadNode(projectName, nodeUuid, language) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // TODO: Language should be empty for default fallback behaviour.
            // Currently the default behaviour in mesh is not desireable.
            // See https://github.com/gentics/mesh/issues/502
            const lang = language || this.config.CONTENT_LANGUAGES.join(',');
            this.state.actions.list.fetchNodeStart();
            return new Promise((resolve, reject) => {
                this.api.project.getNode({ project: projectName, nodeUuid, lang }).subscribe(response => {
                    this.state.actions.list.fetchNodeSuccess(response);
                    resolve();
                }, error => {
                    this.state.actions.list.fetchChildrenError();
                    reject();
                    throw new Error('TODO: Error handling');
                });
            });
        });
    }
    /**
     * Create an placeholder object in the state for the new node
     * and open dispatch an action to open it in the editor
     */
    createNode(projectName, schemaUuid, parentNodeUuid, language) {
        this.api.project.getNode({ project: projectName, nodeUuid: parentNodeUuid }).subscribe(response => {
            this.state.actions.list.fetchNodeSuccess(response);
            this.state.actions.editor.openNewNode(projectName, schemaUuid, parentNodeUuid, language);
        }, error => {
            this.state.actions.list.fetchChildrenError();
            throw new Error('TODO: Error handling');
        });
    }
    /**
     * Save a new node to the api endpoint
     */
    saveNewNode(projectName, node, tags) {
        this.state.actions.editor.saveNodeStart();
        const language = node.language || this.config.FALLBACK_LANGUAGE;
        const nodeCreateRequest = {
            fields: this.getMeshNodeNonBinaryFields(node),
            parentNode: node.parentNode,
            schema: node.schema,
            language: language
        };
        // TODO: remote lang lang: language from params.
        // It is currently needed to overcome the https://github.com/gentics/mesh/issues/404 issue
        // what it does now, it adds ?lang=language query param
        return this.api.project
            .createNode({ project: projectName, lang: language }, nodeCreateRequest)
            .toPromise()
            .then(this.notification.promiseSuccess('editor.node_saved'))
            .then(updatedNode => this.processTagsAndBinaries(node, updatedNode, tags))
            .then(savedNode => {
            this.state.actions.editor.saveNodeSuccess(savedNode);
            return savedNode;
        }, (error) => {
            this.state.actions.editor.saveNodeError();
            // For the new nodes, if something went wrong while saving - delete the node immediately.
            // That way the editor will decide what to do next (stay in an unchanged state?),
            this.api.project
                .deleteNode({ project: projectName, nodeUuid: error.node.uuid })
                .pipe(take(1))
                .subscribe();
            throw error.error;
        });
    }
    /**
     * Update an existing node
     */
    saveNode(node, tags) {
        if (!node.project.name) {
            throw new Error('Project name is not available');
        }
        this.state.actions.editor.saveNodeStart();
        const language = node.language || this.config.FALLBACK_LANGUAGE;
        const updateRequest = {
            fields: this.getMeshNodeNonBinaryFields(node),
            version: node.version,
            language: language
        };
        return this.api.project
            .updateNode({ project: node.project.name, nodeUuid: node.uuid, language }, updateRequest)
            .pipe(filter((response) => !isNullOrUndefined(response)))
            .toPromise()
            .then((response) => {
            // if successful, return data
            if (response && response.node) {
                return this.processTagsAndBinaries(node, response.node, tags);
                // if errornous, return server response with error data
            }
            if (response && response.conflict) {
                return Promise.reject(response);
                // if response won't match any properties, throw error
            }
            else {
                throw new Error('No node was returned from the updateNode API call.');
            }
        })
            .then(this.notification.promiseSuccess('editor.node_saved'))
            .then(savedNode => {
            this.state.actions.editor.saveNodeSuccess(savedNode);
            return savedNode;
        }, error => {
            this.state.actions.editor.saveNodeError();
            throw error;
        });
    }
    publishNode(node) {
        if (!node.project.name) {
            throw new Error('Project name is not available');
        }
        this.state.actions.editor.publishNodeStart();
        this.api.project
            .publishNode({ project: node.project.name, nodeUuid: node.uuid })
            .pipe(this.notification.rxSuccess('editor.node_published'))
            .subscribe(response => {
            if (!node.language) {
                throw new Error('Could not find language of node!');
            }
            const newNode = Object.assign({}, node, { availableLanguages: response.availableLanguages, version: response.availableLanguages[node.language].version });
            this.state.actions.editor.publishNodeSuccess(newNode);
        }, error => {
            this.state.actions.editor.publishNodeError();
        });
    }
    publishNodes(nodes) {
        this.state.actions.editor.publishNodesStart();
        forkJoin(nodes.map((node) => {
            if (!node.project.name) {
                throw new Error('Project name is not available');
            }
            this.state.actions.editor.publishNodeStart();
            return this.api.project.publishNode({ project: node.project.name, nodeUuid: node.uuid }).pipe(tap((response) => {
                if (!node.language) {
                    throw new Error('Could not find language of node!');
                }
                if (this.state.now.entities.node[node.uuid] &&
                    this.state.now.entities.node[node.uuid][node.language] &&
                    this.state.now.entities.node[node.uuid][node.language][node.version]) {
                    const nodeInState = this.state.now.entities.node[node.uuid][node.language][node.version];
                    const newNode = Object.assign({}, nodeInState, { availableLanguages: response.availableLanguages, version: response.availableLanguages[node.language].version });
                    this.state.actions.editor.publishNodeSuccess(newNode);
                }
                else {
                    this.state.actions.editor.publishNodeSuccess();
                }
            }), catchError(error => {
                this.state.actions.editor.publishNodeError();
                return of(null);
            }));
        }))
            .pipe(this.notification.rxSuccessNext('editor.nodes_published', (result) => ({
            amount: result.filter(entry => entry !== null).length
        })))
            .subscribe((result) => {
            this.state.actions.editor.publishNodesSuccess();
        }, error => {
            this.state.actions.editor.publishNodesError();
        });
    }
    publishNodeLanguage(node) {
        if (!node.project.name) {
            throw new Error('Project name is not available');
        }
        if (!node.language) {
            throw new Error('Language is node available');
        }
        this.state.actions.editor.publishNodeStart();
        this.api.project
            .publishNodeLanguage({ project: node.project.name, nodeUuid: node.uuid, language: node.language })
            .pipe(this.notification.rxSuccessNext('editor.node_language_published', version => ({
            version: version.version
        })))
            .subscribe(response => {
            if (!node.language) {
                throw new Error('Could not find language of node!');
            }
            const newNode = Object.assign({}, node, { availableLanguages: Object.assign({}, node.availableLanguages, { [node.language]: response }), version: response.version });
            this.state.actions.editor.publishNodeSuccess(newNode);
        }, error => {
            this.state.actions.editor.publishNodeError();
        });
    }
    publishNodesLanguage(nodes) {
        this.state.actions.editor.publishNodesStart();
        forkJoin(nodes.map((node) => {
            if (!node.project.name) {
                throw new Error('Project name is not available');
            }
            if (!node.language) {
                throw new Error('Language is node available');
            }
            this.state.actions.editor.publishNodeStart();
            return this.api.project
                .publishNodeLanguage({ project: node.project.name, nodeUuid: node.uuid, language: node.language })
                .pipe(tap((response) => {
                if (!node.language) {
                    throw new Error('Could not find language of node!');
                }
                if (this.state.now.entities.node[node.uuid] &&
                    this.state.now.entities.node[node.uuid][node.language] &&
                    this.state.now.entities.node[node.uuid][node.language][node.version]) {
                    const nodeInState = this.state.now.entities.node[node.uuid][node.language][node.version];
                    const newNode = Object.assign({}, nodeInState, { availableLanguages: Object.assign({}, nodeInState.availableLanguages, { [node.language]: response }), version: response.version });
                    this.state.actions.editor.publishNodeSuccess(newNode);
                }
                else {
                    this.state.actions.editor.publishNodeSuccess();
                }
            }), catchError(error => {
                this.state.actions.editor.publishNodeError();
                return of(null);
            }));
        }))
            .pipe(this.notification.rxSuccessNext('editor.nodes_published', (result) => ({
            amount: result.filter(entry => entry !== null).length
        })))
            .subscribe((result) => {
            this.state.actions.editor.publishNodesSuccess();
        }, error => {
            this.state.actions.editor.publishNodesError();
        });
    }
    unpublishNode(node, recursive = false) {
        this.state.actions.editor.unpublishNodeStart();
        if (!node.project.name) {
            throw new Error('Project name is not available');
        }
        const publishingParams = recursive
            ? { project: node.project.name, nodeUuid: node.uuid, recursive: true }
            : { project: node.project.name, nodeUuid: node.uuid };
        this.api.project
            .unpublishNode(publishingParams)
            .pipe(switchMap(() => this.api.project.getNodePublishStatus({
            project: node.project.name,
            nodeUuid: node.uuid
        })), this.notification.rxSuccess('editor.node_unpublished'))
            .subscribe(response => {
            if (!node.language) {
                throw new Error('Could not find language of node!');
            }
            const newNode = Object.assign({}, node, response);
            this.state.actions.editor.unpublishNodeSuccess(newNode);
        }, error => {
            this.state.actions.editor.unpublishNodeError();
        });
    }
    unpublishNodeLanguage(node) {
        this.state.actions.editor.unpublishNodeStart();
        if (!node.project.name) {
            throw new Error('Project name is not available');
        }
        if (!node.language) {
            throw new Error('Language is node available');
        }
        if (!node.availableLanguages[node.language].published) {
            this.notification.show({
                message: 'editor.node_already_unpublished'
            });
            return;
        }
        this.api.project
            .unpublishNodeLanguage({ project: node.project.name, nodeUuid: node.uuid, language: node.language })
            .pipe(switchMap(() => this.api.project.getNodePublishStatus({
            project: node.project.name,
            nodeUuid: node.uuid
        })), this.notification.rxSuccess('editor.node_unpublished'))
            .subscribe(response => {
            if (!node.language) {
                throw new Error('Could not find language of node!');
            }
            const newNode = Object.assign({}, node, response);
            this.state.actions.editor.unpublishNodeSuccess(newNode);
        }, error => {
            this.state.actions.editor.unpublishNodeError();
        });
    }
    getMeshNodeNonBinaryFields(node) {
        const schema = this.entities.getSchema(node.schema.uuid);
        const binaryFields = getBinaryOrS3BinaryTypeMeshNodeFields(node, schema, 'binary');
        const s3binaryFields = getBinaryOrS3BinaryTypeMeshNodeFields(node, schema, 's3binary');
        return Object.keys(node.fields).reduce((nonBinaryFields, key) => {
            if ((binaryFields[key] === undefined && s3binaryFields[key] === undefined) ||
                // A binary or s3binary field should be included if it should be deleted
                node.fields[key] === null) {
                nonBinaryFields[key] = stripNulls(node.fields[key]);
            }
            return nonBinaryFields;
        }, {});
    }
    closeEditor() {
        this.state.actions.editor.closeEditor();
    }
    /**
     * Creates a translation of a node by cloning the given node and renaming certain fields which need to be unique.
     * This method is limited in that it does not work with binary fields and the renaming is naive and may fail.
     * TODO: update this when a translation endpoint in implemented in Mesh: https://github.com/gentics/mesh/issues/12
     */
    createTranslation(node, languageCode) {
        const clone = this.cloneNodeWithRename(node, languageCode.toUpperCase());
        if (clone) {
            clone.language = languageCode;
            return this.saveNode(clone, node.tags);
        }
        else {
            return Promise.reject(`Could not create translation`);
        }
    }
    /**
     * After creating or updating a node, a common set of operations needs to be performed, namely:
     * * Updating the node's tags
     * * Uploading any new binary files that have been selected for the node
     * * Applying any binary transforms
     */
    processTagsAndBinaries(originalNode, updatedNode, tags) {
        const schema = this.entities.getSchema(originalNode.schema.uuid);
        return this.assignTagsToNode(updatedNode, tags)
            .then(newNode => this.uploadBinaries(newNode, getBinaryOrS3BinaryTypeMeshNodeFields(originalNode, schema, 'binary')))
            .then(newNode => this.uploadS3Binaries(newNode, getBinaryOrS3BinaryTypeMeshNodeFields(originalNode, schema, 's3binary')))
            .then(newNode => newNode && this.applyBinaryTransforms(newNode, originalNode.fields));
    }
    assignTagsToNode(node, tags) {
        if (!tags) {
            return Promise.resolve(node);
        }
        return this.api.project
            .assignTagsToNode({ project: node.project.name, nodeUuid: node.uuid }, { tags })
            .toPromise()
            .then(() => node);
    }
    /**
     * Clones a node and changes the fields which should be unique in a given parentNode (i.e. displayField,
     * segmentField) by adding a suffix.
     */
    cloneNodeWithRename(node, suffix) {
        const clone = simpleCloneDeep(node);
        const schema = this.entities.getSchema(node.schema.uuid);
        if (schema) {
            const displayField = schema.displayField;
            const segmentField = schema.segmentField;
            const urlFields = schema.urlFields;
            const fieldsToBeSuffixed = new Set();
            const addToFieldsToBeSuffixed = (fieldKey) => {
                if (!fieldKey) {
                    return;
                }
                if (node.fields[fieldKey]) {
                    if (node.fields[fieldKey].sha512sum) {
                        clone.fields[fieldKey].fileName = this.addSuffixToString(node.fields[fieldKey].fileName, suffix);
                    }
                    else {
                        fieldsToBeSuffixed.add(fieldKey);
                    }
                }
            };
            addToFieldsToBeSuffixed(displayField);
            addToFieldsToBeSuffixed(segmentField);
            // if node has urlFields
            if (urlFields && urlFields.length > 0) {
                urlFields.forEach(urlField => {
                    // add to be suffixed
                    addToFieldsToBeSuffixed(urlField);
                });
            }
            // suffix fields
            fieldsToBeSuffixed.forEach(fieldToBeSuffixed => {
                clone.fields[fieldToBeSuffixed] = this.addSuffixToString(node.fields[fieldToBeSuffixed], suffix);
            });
            // Display a warning if there are any binary fields - these cannot be handled properly
            // until the dedicated translation endpoint is implemented in Mesh.
            const firstBinaryField = this.getFirstBinaryField(node);
            if (firstBinaryField && firstBinaryField.key !== undefined) {
                console.warn(`Note: binary fields cannot yet be copied.`);
            }
            return clone;
        }
    }
    /**
     * Given a string value, append the suffix to the end.
     * If the value has periods in it (as in a file name), then insert
     * the suffix before the file extension:
     *
     * foo => foo_de
     * foo.html => foo.de.html
     */
    addSuffixToString(value, suffix, delimiter = '-') {
        const parts = value.split('.');
        if (1 < parts.length) {
            parts.splice(-1, 0, suffix);
            return parts.join('.');
        }
        else {
            return value + delimiter + suffix;
        }
    }
    /**
     * Given a node, check for any binary fields if one if found, return the first
     * in an object with key (field name) and value (binary field properties).
     */
    getFirstBinaryField(node) {
        let binaryFieldKey;
        let binaryFieldValue;
        if (node) {
            for (const key in node.fields) {
                if (node.fields.hasOwnProperty(key)) {
                    const field = node.fields[key];
                    if (field && field.fileSize) {
                        if (binaryFieldValue === undefined) {
                            binaryFieldKey = key;
                            binaryFieldValue = field;
                        }
                    }
                }
            }
            if (binaryFieldKey && binaryFieldValue) {
                return {
                    key: binaryFieldKey,
                    value: binaryFieldValue
                };
            }
        }
    }
    uploadBinaries(node, fields) {
        const projectName = node.project.name;
        const language = node.language;
        // if no binaries are present - return the same node
        if (Object.keys(fields).length === 0 || !projectName || !language) {
            return Promise.resolve(node);
        }
        const promiseSuppliers = Object.keys(fields).map(key => () => this.uploadBinary(projectName, node.uuid, key, fields[key].file, language, node.version).catch(error => {
            throw { field: fields[key], node, error };
        }));
        return (promiseConcat(promiseSuppliers)
            // return the node from the last successful request
            .then(nodes => nodes[nodes.length - 1]));
    }
    uploadS3Binaries(node, fields) {
        const projectName = node.project.name;
        const language = node.language;
        // if no s3binaries are present - return the same node
        if (Object.keys(fields).length === 0 || !projectName || !language) {
            return Promise.resolve(node);
        }
        const promiseSuppliers = Object.keys(fields).map(key => () => this.uploadS3Binary(projectName, node.uuid, key, fields[key].file, language, node.version).catch(error => {
            throw { field: fields[key], node, error };
        }));
        return (promiseConcat(promiseSuppliers)
            // return the node from the last successful request
            .then(nodes => nodes[nodes.length - 1]));
    }
    uploadBinary(project, nodeUuid, fieldName, binary, language, version) {
        // TODO: remote lang lang: language from params.
        // It is currently needed to overcome the https://github.com/gentics/mesh/issues/404 issue
        // what it does now, it adds ?lang=language query param
        return this.api.project
            .updateBinaryField({
            project,
            nodeUuid,
            fieldName,
            lang: language
        }, {
            binary,
            language,
            version
        })
            .toPromise();
    }
    uploadS3Binary(project, nodeUuid, fieldName, s3binary, language, version) {
        return this.generateS3Url(project, nodeUuid, fieldName, language, version, s3binary.name).then(response => this.uploadToS3(response, s3binary)
            .then(() => this.parseMetadata(project, nodeUuid, fieldName, language, response.version))
            .catch(error => {
            throw { s3binary, error };
        }));
    }
    generateS3Url(project, nodeUuid, fieldName, language, version, filename) {
        return this.api.project
            .generateS3Url({
            project,
            nodeUuid,
            fieldName
        }, {
            language,
            version,
            filename
        })
            .toPromise();
    }
    uploadToS3(properties, s3binary) {
        return this.http.put(properties.presignedUrl, s3binary).toPromise();
    }
    parseMetadata(project, nodeUuid, fieldName, language, version) {
        return this.api.project
            .parseMetadata({
            project,
            nodeUuid,
            fieldName
        }, {
            language,
            version
        })
            .toPromise();
    }
    applyBinaryTransforms(node, fields) {
        const project = node.project.name;
        const nodeUuid = node.uuid;
        const language = node.language;
        if (!project || !nodeUuid || !language) {
            return Promise.reject('Project name, node language or node uuid not available.');
        }
        const promises = Object.keys(fields)
            .filter(fieldName => fields[fieldName] && !!fields[fieldName].transform)
            .map(fieldName => {
            const value = fields[fieldName];
            const transform = value.transform;
            return this.api.project
                .transformBinaryField({
                project,
                nodeUuid,
                fieldName
            }, {
                version: node.version,
                language: language,
                width: transform.width,
                height: transform.height,
                cropRect: transform.cropRect
            })
                .toPromise();
        });
        if (!promises.length) {
            return Promise.resolve(node);
        }
        return (Promise.all(promises)
            // return the node from the last successful request
            .then(nodes => nodes[nodes.length - 1])
            .catch(error => {
            throw { node, error };
        }));
    }
}
