import * as tslib_1 from "tslib";
import { CloneDepth, Immutable, StateActionBranch } from 'immutablets';
import { LOCAL_STORAGE_LANG_KEY } from '../../common/constants';
let UIStateActions = class UIStateActions extends StateActionBranch {
    constructor() {
        super({
            uses: ['ui'],
            initialState: {
                ui: {
                    currentLanguage: 'en',
                    searchAvailable: false
                }
            }
        });
    }
    setLanguage(newUiLanguage) {
        this.ui.currentLanguage = newUiLanguage;
        localStorage.setItem(LOCAL_STORAGE_LANG_KEY, newUiLanguage);
    }
    setSearchAvailable(searchAvailable) {
        this.ui.searchAvailable = searchAvailable;
    }
};
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], UIStateActions.prototype, "ui", void 0);
UIStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [])
], UIStateActions);
export { UIStateActions };
