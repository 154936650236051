import * as tslib_1 from "tslib";
import { CloneDepth, Immutable, StateActionBranch } from 'immutablets';
let AdminRolesStateActions = class AdminRolesStateActions extends StateActionBranch {
    constructor() {
        super({
            uses: ['adminRoles'],
            initialState: {
                adminRoles: {
                    roleDetail: null
                }
            }
        });
    }
    loadRoleSuccess(role) {
        this.adminRoles.roleDetail = role;
    }
};
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], AdminRolesStateActions.prototype, "adminRoles", void 0);
AdminRolesStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [])
], AdminRolesStateActions);
export { AdminRolesStateActions };
