import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import * as ScheduleUtil from 'src/app/common/util/schedule-util';
import { ApplicationStateService } from 'src/app/state/providers/application-state.service';

import { MeshNode } from '../../../common/models/node.model';
import { I18nService, UILanguage } from '../../../core/providers/i18n/i18n.service';

@Component({
    selector: 'mesh-info-box',
    templateUrl: './info-box.component.html',
    styleUrls: ['./info-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBoxComponent implements OnChanges, OnDestroy {
    @Input() node: MeshNode;

    /** Component lifecycle indicator */
    private destroy$ = new Subject<void>();

    text: string;

    uiLanguage: UILanguage;

    scheduleUtil = ScheduleUtil;

    constructor(private appState: ApplicationStateService, private i18n: I18nService) {
        this.uiLanguage = appState.now.ui.currentLanguage;
    }

    ngOnChanges(): void {
        const scheduleJson = this.node.fields.nodeUpdateSchedule;
        const startDate = this.scheduleUtil.getScheduleDate(scheduleJson, 'startDate');
        const endDate = this.scheduleUtil.getScheduleDate(scheduleJson, 'endDate');

        let translationKey = 'editor.schedule_infobox';
        translationKey = !startDate ? 'editor.schedule_infobox_no_start' : translationKey;
        translationKey = !endDate ? 'editor.schedule_infobox_no_end' : translationKey;

        this.text = this.i18n.translate(translationKey, {
            startDate: this.scheduleUtil.formatDate(this.uiLanguage, startDate),
            startTime: this.scheduleUtil.formatTime(this.uiLanguage, startDate),
            endDate: this.scheduleUtil.formatDate(this.uiLanguage, endDate),
            endTime: this.scheduleUtil.formatTime(this.uiLanguage, endDate)
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
