import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { IModalDialog, ModalService, Notification } from '@bewatec-berlin/gentics-ui-core';
import { combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MeshNode } from 'src/app/common/models/node.model';
import * as ScheduleUtil from 'src/app/common/util/schedule-util';
import { I18nService } from 'src/app/core/providers/i18n/i18n.service';

import { ADDED_INTERVAL_IN_MINUTES, ADDED_START_INTERVAL_IN_MINUTES } from '../../../common/constants';
import { ConfigService } from '../../../core/providers/config/config.service';
import { EditorEffectsService } from '../../providers/editor-effects.service';
import { ConfirmUnpublishedModalComponent } from '../confirm-unpublished-modal/confirm-unpublished-modal.component';

@Component({
    selector: 'mesh-scheduling-modal',
    templateUrl: './scheduling-modal.tpl.html',
    styleUrls: ['./scheduling-modal.scss']
})
export class SchedulingModalComponent implements IModalDialog, OnInit {
    private readonly START_INTERVAL = ADDED_START_INTERVAL_IN_MINUTES * 60 * 1000;
    private readonly END_INTERVAL = ADDED_INTERVAL_IN_MINUTES * 60 * 1000;
    form: FormGroup;
    translateToGerman: boolean;
    defaultEndDateTime: number;
    defaultStartDateTime: number;

    // Passed from the caller
    startDateTimestamp?: number;
    endDateTimestamp?: number;
    node: MeshNode;

    isNodePublished = false;
    start$ = new BehaviorSubject<null | number>(null);
    end$ = new BehaviorSubject<null | number>(null);
    private destroy$ = new Subject<void>();
    scheduleUtil = ScheduleUtil;
    private validationObj: { message: string; conflict: boolean } = { message: 'default', conflict: true };

    localLanguage: string;
    validationConstraint?: string = undefined;

    constructor(
        private editorEffects: EditorEffectsService,
        private notification: Notification,
        private i18n: I18nService,
        private config: ConfigService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        this.localLanguage = this.i18n.getSelectedLanguage();
        this.translateToGerman = this.localLanguage === this.config.DEFAULT_LANGUAGE;

        this.defaultStartDateTime = Date.now() + this.START_INTERVAL;
        this.defaultEndDateTime = this.defaultStartDateTime + this.END_INTERVAL;

        try {
            const lang = this.node.language;
            if (lang) {
                this.isNodePublished = this.node.availableLanguages[lang].published;
            }
        } catch (e) {
            this.isNodePublished = false;
        }

        const startDate = new FormControl(
            { value: this.startDateTimestamp || '', disabled: this.isNodePublished },
            Validators.compose([Validators.min(Math.floor(this.defaultStartDateTime / 1000)), this.validStart])
        );
        const endDate = new FormControl(
            {value: this.endDateTimestamp || '', disabled: false},
            Validators.compose([Validators.min(Math.floor(this.defaultEndDateTime / 1000)), this.validEnd])
        );
        this.form = new FormGroup({
            startDate: startDate,
            endDate: endDate
        });

        combineLatest([this.start$, this.end$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(response => {
                const startDT = response[0] ? response[0] * 1000 : null;
                const endDT = response[1] ? response[1] * 1000 : null;
                if (startDT) {
                    this.defaultStartDateTime = startDT
                    this.defaultEndDateTime = startDT + this.END_INTERVAL;
                }

                this.validationObj = { message: 'checking', conflict: false };
                this.validationConstraint = undefined;
                if (this.isNodePublished) {
                    if (startDT) {
                        this.validationConstraint = 'isPublished';
                        this.validationObj = {
                            conflict: true,
                            message: 'scheduled start date not allowed for a published node'
                        };
                    }
                    if (!endDT) {
                        this.validationConstraint = 'requireEndDate';
                        this.validationObj = {
                            conflict: true,
                            message: 'scheduled end date should exist'
                        };
                    }
                } else {
                    if (!startDT) {
                        this.validationConstraint = 'requireStartDate';
                        this.validationObj = { conflict: true, message: 'scheduled start date should exist' };
                    }
                    if (endDT && startDT && endDT < startDT + this.END_INTERVAL) {
                        this.validationConstraint = 'requireLaterEndDate';
                        this.validationObj = {
                            conflict: true,
                            message: 'scheduled end date should be grater then scheduled start date'
                        };
                    }
                }
            });
        this.form.setValidators(Validators.compose([this.dateValidator]));
    }

    closeFn = (val?: any) => {};
    cancelFn = (val?: any) => {};

    registerCloseFn(close: (val: any) => void): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.closeFn = close;
    }

    registerCancelFn(cancel: (val?: any) => void): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.cancelFn = cancel;
    }

    async schedulePublication() {
        if (!this.node.container || !this.end$.getValue()) {
            this.actionPublish(this.node);
            this.closeFn();
        } else {
            const options = {
                closeOnOverlayClick: false
            };
            this.modalService
                .fromComponent(ConfirmUnpublishedModalComponent, options, { node: this.node, willBeScheduled: true })
                .then(modal => modal.open())
                .then(resp => {
                    if (resp) {
                        this.actionPublish(this.node);
                        this.closeFn();
                    }
                });
        }
    }

    private dateValidator: (control: AbstractControl) => ValidationErrors | null = control => {
        if (this.validationObj.conflict) {
            return this.validationObj;
        }
        return null;
    };

    private validStart: (control: AbstractControl) => ValidationErrors | null = control => {
        if (control.value) {
            this.start$.next(control.value);
        } else {
            this.start$.next(0);
        }
        return null;
    };

    private validEnd: (control: AbstractControl) => ValidationErrors | null = control => {
        if (control.value) {
            this.end$.next(control.value);
        } else {
            this.end$.next(0);
        }
        return null;
    };

    private actionPublish(node: MeshNode) {
        try {
            this.scheduleUtil.setNodeUpdateSchemaValue(node, this.form);
            this.editorEffects.saveNode(node).then(() => {
                if (this.isNodePublished && parseInt(node.version.split('.')[1], 10) === 0) {
                    this.editorEffects.publishNode(node);
                }
            });
        } catch (err) {
            this.notification.show({
                type: 'error',
                message: err.toString()
            });
        }
    }
}
