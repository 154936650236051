/**
 * Utils for scheduling publication
 */
const getNextMondayTimestamp = (date) => {
    date.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7 || 7));
    date.setHours(8);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime() / 1000;
};
const ɵ0 = getNextMondayTimestamp;
const setNodeUpdateSchemaValue = (node, form) => {
    node.fields.nodeUpdateSchedule = JSON.stringify({
        startDate: form.controls.startDate.value ? form.controls.startDate.value.toString() : '',
        endDate: form.controls.endDate.value ? form.controls.endDate.value.toString() : '',
        language: node.language
    });
};
const ɵ1 = setNodeUpdateSchemaValue;
const clearNodeUpdateSchemaValue = (node) => {
    node.fields.nodeUpdateSchedule = null;
};
const ɵ2 = clearNodeUpdateSchemaValue;
const getScheduleDate = (json, value) => {
    try {
        const readObj = JSON.parse(json);
        const date = readObj[value];
        if (!date) {
            return;
        }
        return new Date(parseInt(date, 10) * 1000);
    }
    catch (_a) {
        return;
    }
};
const ɵ3 = getScheduleDate;
const formatDate = (lang, date) => {
    if (!date) {
        return '';
    }
    return new Intl.DateTimeFormat(lang, { formatMatcher: 'basic' }).format(date);
};
const ɵ4 = formatDate;
const formatTime = (lang, date) => {
    if (!date) {
        return '';
    }
    // @ts-ignore Argument of type '{ timeStyle: string; }' is not assignable to parameter of type 'DateTimeFormatOptions'.
    return new Intl.DateTimeFormat(lang, { timeStyle: 'short' }).format(date);
};
const ɵ5 = formatTime;
export { getNextMondayTimestamp, setNodeUpdateSchemaValue, clearNodeUpdateSchemaValue, getScheduleDate, formatDate, formatTime };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
