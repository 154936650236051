import { FormControl } from '@angular/forms';

import { EMeshNodeStatusStrings } from '../../shared/components/node-status/node-status.component';
import {
    BinaryField,
    FieldMap,
    ListNodeFieldType,
    MeshNode,
    MicronodeFieldMap,
    MicronodeFieldType,
    NodeFieldType,
    ProjectNode
} from '../models/node.model';
import { Schema, SchemaField, SchemaFieldType } from '../models/schema.model';
import { FieldMapFromServer, GraphQLResponse } from '../models/server-models';

type Supplier<T> = () => T;

// Pure functions for utility

export type ComponentChanges<T> = { [V in keyof T]: ComponentChange<T[V]> };

export interface ComponentChange<T> {
    previousValue: T;
    currentValue: T;
    firstChange: boolean;
    isFirstChange(): boolean;
}

/**
 * Retrieves all values of an object as an array.
 * @param object Any object
 */
export function hashValues<T>(object: { [key: string]: T }): T[] {
    return Object.keys(object).map(key => object[key]);
}

/**
 * Checks if the provided field is an image field.
 */
export function isImageField(field: BinaryField): boolean {
    return field && field.mimeType.startsWith('image/');
}

/**
 * Checks if a value is not null or undefined.
 * @example
 *     appState.select(state => state.possiblyUndefinedValue)
 *         .filter(notNullOrUndefined)
 */
export function notNullOrUndefined<T extends string | number | boolean | object>(
    input: T | null | undefined
): input is T {
    return input != null;
}

/**
 * Checks if all values of an array are equal (by reference).
 * @example
 *     appState.select(state => state.possiblyUndefinedValue)
 *         .distinctUntilChanged(arrayContentsEqual)
 */
export function arrayContentsEqual<T>(a: T[], b: T[]): boolean {
    return a === b || (a && b && a.length === b.length && a.every((value, index) => b[index] === value));
}

/**
 * Returns the extension of a filename.
 */
export function filenameExtension(filename: string): string {
    const index = filename.lastIndexOf('.');
    if (index < 0) {
        return '';
    } else {
        return filename.substring(index);
    }
}

/**
 * Creates an query string from the provided object.
 * Uses all properties from the object that are not undefined or null.
 * This will prepend an '?' if at least one valid property is found.
 *
 * TODO Add url encode or use angular URLSearchParams
 */
export function queryString(obj: any): string {
    let qs = Object.keys(obj)
        .reduce<string[]>((query, key) => {
            const val = obj[key];
            if (val !== undefined && val !== null) {
                query.push(`${key}=${val}`);
            }
            return query;
        }, [])
        .join('&');

    if (qs.length > 0) {
        qs = '?' + qs;
    }
    return qs;
}

/**
 * Concatenates two or more arrays and de-duplicates and duplicate values.
 *
 * @example
 * concatUnique([1, 2, 4, 6], [2, 4, 0, 7]);
 * // => [1, 2, 4, 6, 0, 7]
 */
export function concatUnique<T>(first: T[], ...rest: T[][]): T[] {
    const all = [first, ...rest].reduce((acc, curr) => acc.concat(curr), []);
    return Array.from(new Set(all));
}

/**
 * Creates an object out of an array, which has elements with uuids. These uuids are used
 * for the keys of the object.
 * This is useful for transforming a list response from mesh to a format suitable to the state.
 */
export function uuidHash<T extends { uuid: string }>(elements: T[]): { [uuid: string]: T } {
    return elements.reduce(
        (hash, element) => {
            hash[element.uuid] = element;
            return hash;
        },
        {} as { [uuid: string]: T }
    );
}

export function noop() {}
export function id<T>(obj: T): T {
    return obj;
}

export type Primitive = string | number | boolean;
export interface SimpleObject {
    [key: string]: SimpleObject | Primitive | SimpleArray;
}
export type SimpleArray = Array<SimpleObject | Primitive>;
export type SimpleDeepEqualsType =
    | Primitive
    | SimpleObject
    | SimpleArray
    | NodeFieldType
    | ListNodeFieldType
    | MicronodeFieldType
    | MicronodeFieldMap;

/**
 * An simple object equality function designed for primitives (string, number, boolean), plain objects, arrays, or any combination thereof.
 */
export function simpleDeepEquals<T extends SimpleDeepEqualsType>(o1?: T, o2?: T): boolean {
    if (isPrimitiveValue(o1) || isPrimitiveValue(o2)) {
        return o1 === o2;
    }

    if (!o1 || !o2) {
        return o1 === o2;
    }

    const keys1 = Object.keys(o1);
    const keys2 = Object.keys(o2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let i = keys1.length - 1; i >= 0; i--) {
        const key = keys1[i] as keyof SimpleDeepEqualsType;
        if (!simpleDeepEquals(o1[key], o2[key])) {
            return false;
        }
    }

    return true;
}

function isPrimitiveValue(arg: any): boolean {
    return typeof arg === 'string' || typeof arg === 'number' || typeof arg === 'boolean' || arg === null;
}

function isObject(item: any): item is object {
    return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects. Objects should be simple (bags key-values or arrays) - no circular references or functions, class instances etc.
 * Array values are overwritten rather than merged.
 * Based on: https://stackoverflow.com/a/34749873/772859
 */
export function simpleMergeDeep(target: { [key: string]: any }, ...sources: { [key: string]: any }[]): any {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!(target as any)[key]) {
                    Object.assign(target, { [key]: {} });
                }
                simpleMergeDeep((target as any)[key], source[key]);
            } else {
                const value = Array.isArray(source[key]) ? source[key].slice(0) : source[key];
                Object.assign(target, { [key]: value });
            }
        }
    }

    return simpleMergeDeep(target, ...sources);
}

/**
 * Clone a simple object (no functions, no circular references, no class instances)
 *
 * Apparently the fastest way to do this in JS, since the JSON methods are implemented in
 * native code and will be faster than any recursive JS-based approach. See https://stackoverflow.com/a/5344074/772859
 */
export function simpleCloneDeep<T>(target: T): T {
    return JSON.parse(JSON.stringify(target));
}

/**
 * Filter all the binary or s3binary fields from the node
 */
export function getBinaryOrS3BinaryTypeMeshNodeFields(
    node: MeshNode,
    schema: Schema | undefined,
    type: SchemaFieldType
): FieldMap {
    if (!node.fields || !schema) {
        return {} as FieldMapFromServer;
    }
    const types = ['binary', 's3binary'];

    return Object.keys(node.fields).reduce(
        (fields, key) => {
            const nodeField = node.fields[key];
            if (types.includes(type) && nodeField && (nodeField.file && nodeField.file instanceof File) === true) {
                const schemaField = schema.fields.find(field => field.name === key);
                if (schemaField && schemaField.type === type) {
                    fields[key] = nodeField;
                }
            }
            return fields;
        },
        {} as FieldMap
    );
}

export function stripNulls<T>(arr: T): T {
    // if it's array, delete all the nulls and return array without nulls
    // if it's not array, just return it
    if (Array.isArray(arr)) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] == null) {
                arr.splice(i, 1);
                i--;
            }
        }
        return arr;
    } else {
        return arr;
    }
}

export function stringToColor(input: string): string {
    const colors = [
      '#e62739',
      '#CC0000',
      '#990000',
      '#660000',
      '#C6F6D5',
      '#68D391',
      '#38A169',
      '#2F855A',
      '#2E8B57',
      '#246B45',
      '#1A4B33',
      '#103B21',
      '#9ACD32',
      '#7AA028',
      '#5A731E',
      '#3A4614',
      '#6ed3cf',
      '#00CCCC',
      '#009999',
      '#006666',
      '#5F9EA0',
      '#4A7A80',
      '#365660',
      '#223240',
      '#256de6',
      '#89c4e3',
      '#59a9e1',
      '#2b305d',
      '#4682B4',
      '#366A8C',
      '#285064',
      '#1A3644',
      '#FFC0CB',
      '#FF99A1',
      '#FF7381',
      '#FF4D61',
      '#A52A2A',
      '#852222',
      '#651A1A',
      '#451212',
      '#C884FF',
      '#A668CE',
      '#613D7A',
      '#4A2E5E',
      '#D2691E',
      '#A95218',
      '#7D3B12',
      '#51240C',
      '#8B4513',
      '#6E3710',
      '#52290C',
      '#361B08',
      '#CC8400',
      '#FFA500',
      '#996300',
      '#664200',
      '#800080',
      '#660066',
      '#4D004D',
      '#330033',
      '#DAA520',
      '#AA7F18',
      '#7A5910',
      '#4A3308',
      '#CCCC00',
      '#999900',
      '#FBC02D',
      '#666600',
      '#D8BFD8',
      '#B09FA8',
      '#887F88',
      '#605F68',
      '#BC8F8F',
      '#967070',
      '#705151',
      '#4A3232'
    ];

    const isExcludeTagColor = input.endsWith('_EXCLUDE');

    input = isExcludeTagColor ? input.substring(0, input.indexOf('_EXCLUDE')) : input;

    const value = input.split('').reduce((prev, curr, index) => {
      return prev + Math.round(curr.charCodeAt(0) * Math.log(index + 2));
    }, 0);

    return colors[value % colors.length];
  }

/**
 * Executes functions that return promises one at a time
 * @param promiseSuppliers Functions that return promises
 * @returns An array of all promise results
 */
export async function promiseConcat<T>(promiseSuppliers: Supplier<T>[]): Promise<T[]> {
    const results: T[] = [];
    for (const supplier of promiseSuppliers) {
        results.push(await supplier());
    }
    return results;
}

/**
 * Extracts the data property from a GraphQl response.
 * Throws an error if the error property is present.
 */
export function extractGraphQlResponse(response: GraphQLResponse): any {
    if (response.errors) {
        throw new Error(JSON.stringify(response, undefined, 2));
    } else {
        return response.data;
    }
}

/**
 * Creates an object with an entry for every item in the array.
 *
 * @param keyMapper A function that retrieves the key of the entry from an item
 * @param valueMapper A function that retrieves the value of the entry from an item
 * @param array The array to create the object of
 */
export function toObject<T, V>(
    keyMapper: (item: T) => string,
    valueMapper: (item: T) => V,
    array: T[]
): { [key: string]: V } {
    const obj: { [key: string]: V } = {};
    for (const item of array) {
        obj[keyMapper(item)] = valueMapper(item);
    }
    return obj;
}

/**
 * @description Checks input value to be a human-readable string (blankspaces only not allowed)
 * @param value to be examined if is human-readable string
 * */
export function isValidString(value: string | any): value is string {
    if (value && typeof value === 'string' && value !== '' && new RegExp(/^\s+$/).test(value) === false) {
        return true;
    } else {
        return false;
    }
}

export function matchOtherValidator(otherControlName: string) {
    let thisControl: FormControl;
    let otherControl: FormControl;

    return function matchOtherValidate(control: FormControl) {
        if (!control.parent) {
            return null;
        }

        // Initializing the validator.
        if (!thisControl) {
            thisControl = control;
            otherControl = control.parent.get(otherControlName) as FormControl;
            if (!otherControl) {
                throw new Error('matchOtherValidator(): other control is not found in parent group');
            }
            otherControl.valueChanges.subscribe(() => {
                thisControl.updateValueAndValidity();
            });
        }

        if (!otherControl) {
            return null;
        }

        if (otherControl.value !== thisControl.value) {
            return {
                matchOther: true
            };
        }

        return null;
    };
}

export function projectNodeEquals(a?: ProjectNode, b?: ProjectNode): boolean {
    if (!a && !b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    return a.branch === b.branch && nodeEquals(a.node, b.node);
}

export function nodeEquals(a?: MeshNode, b?: MeshNode): boolean {
    if (!a && !b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    return a.uuid === b.uuid && a.language === b.language && a.version === b.version;
}

export function flatMap<T, R>(arr: T[], mapper: (item: T) => R[]): R[] {
    const result: R[] = [];
    arr.forEach(item => result.push(...mapper(item)));
    return result;
}

/**
 * Returns the last element of an array or undefined if the array is empty or undefined.
 * @param arr
 */
export function last<T>(arr: T[]): T | undefined {
    if (arr && arr.length > 0) {
        return arr[arr.length - 1];
    }
}

/**
 * Upper cases the first letter of a string.
 * @param str
 */
export function capitalize(str: string): string {
    return str[0].toUpperCase() + str.substring(1);
}

/**
 * Parses a node status filter parameter string and returns a pruned array.
 */
export function parseNodeStatusFilterString(nodeStatusFilterString: string): EMeshNodeStatusStrings[] {
    // filter unknown node statuses and remove duplicates
    let nodeStatusFilter: EMeshNodeStatusStrings[] = [
        ...new Set(nodeStatusFilterString.split(',').filter(isEMeshNodeStatusString))
    ];

    // if no filters are set, then it is assumed that no nodes should be filtered (e.g. setting all node statuses as filters)
    if (nodeStatusFilter.length === 0) {
        nodeStatusFilter = Object.values(EMeshNodeStatusStrings);
    }
    return nodeStatusFilter;
}

/**
 * Type predicate that checks whether a given string is of type EMeshNodeStatusStrings
 */
export function isEMeshNodeStatusString(string: string): string is EMeshNodeStatusStrings {
    return (
        typeof string === 'string' && Object.values(EMeshNodeStatusStrings).includes(string as EMeshNodeStatusStrings)
    );
}
