import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IModalDialog } from '@bewatec-berlin/gentics-ui-core';

import { MeshNode } from '../../../common/models/node.model';
import { I18nService } from '../../../core/providers/i18n/i18n.service';
import { EditorEffectsService } from '../../providers/editor-effects.service';

/**
 * A modal for the user to confirm navigation despite unsaved changes.
 */
@Component({
    selector: 'mesh-confirm-unpublished-modal',
    templateUrl: './confirm-unpublished-modal.tpl.html',
    styleUrls: ['./confirm-unpublished-modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmUnpublishedModalComponent implements IModalDialog, OnInit {
    closeFn: (result: boolean) => void;
    cancelFn: (val?: any) => void;
    node: MeshNode;
    willBeScheduled = false;
    text: any;

    constructor(private i18n: I18nService, private editorEffects: EditorEffectsService) {}

    ngOnInit(): void {
        this.text = this.i18n.translate('modal.confirm_unpublished_body', { nodeTitle: this.node.displayName });
    }

    saveAndClose(): void {
        if (!this.willBeScheduled) {
            this.editorEffects.unpublishNode(this.node, true);
        }
        this.closeFn(true);
    }

    registerCloseFn(close: (val: boolean) => void): void {
        this.closeFn = close;
    }

    registerCancelFn(cancel: (val: any) => void): void {
        this.cancelFn = cancel;
    }
}
