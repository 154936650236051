import { Component, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ConfigService } from './core/providers/config/config.service';

import { BewatecConfig } from './common/models/bewatecconfig.model';

import { ApplicationStateDevtools } from './state/providers/application-state-devtools';
import { ApplicationStateService } from './state/providers/application-state.service';

@Component({
    selector: 'mesh-app',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['app.component.scss'],
    templateUrl: './app.component.html'
})
export class AppComponent {
    loggedIn$: Observable<boolean>;
    bewatecConfig: BewatecConfig;
    adminMode$: Observable<boolean>;

    displayMenu = false;

    constructor(
        public state: ApplicationStateService,
        devtools: ApplicationStateDevtools,
        private router: Router,
        config: ConfigService
    ) {
        this.loggedIn$ = state.select(_state => _state.auth.loggedIn);
        this.adminMode$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map((event: NavigationEnd) => {
                this.displayMenu = false;
                return /^\/admin/.test(event.url);
            })
        );
        if (config.appConfig) {
            this.bewatecConfig = config.appConfig.bewatecConfig;
        }
    }
}
