import * as tslib_1 from "tslib";
import { CloneDepth, Immutable, StateActionBranch } from 'immutablets';
let AdminGroupsStateActions = class AdminGroupsStateActions extends StateActionBranch {
    constructor() {
        super({
            uses: ['adminGroups'],
            initialState: {
                adminGroups: {
                    groupDetail: null
                }
            }
        });
    }
    loadGroupSuccess(group) {
        this.adminGroups.groupDetail = group;
    }
};
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], AdminGroupsStateActions.prototype, "adminGroups", void 0);
AdminGroupsStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [])
], AdminGroupsStateActions);
export { AdminGroupsStateActions };
