<div class="modal-title">
    <gtx-progress-bar></gtx-progress-bar>

    <h5>{{ options.titleKey | i18n:options.titleParams }}</h5>

    <gtx-search-bar *ngIf="searchAvailable$ | async"
                    [placeholder]="'common.search_placeholder' | i18n"
                    [(ngModel)]="searchQuery"
                    (search)="onSearch($event)"
                    (clear)="clearSearch()">
        <mesh-chip *ngIf="searchedTerm"
                   class="search-term"
                   (removeClick)="clearSearch()">
            {{ 'common.searching' | i18n:{ term: searchedTerm } }}
        </mesh-chip>
    </gtx-search-bar>

    <gtx-breadcrumbs collapsedColor="#3d3d42"
                     [links]="breadcrumb$ | async"
                     (linkClick)="currentNode$.next($event.uuid)"></gtx-breadcrumbs>
</div>
<div class="modal-content">

    <mesh-node-browser-list [items]="currentPageContent$ | async | paginate: {
                itemsPerPage: perPage,
                currentPage: currentPage$ | async,
                id: 'nodebrowser',
                totalItems: totalItems$ | async
        }"
                            [multiple]="options.multiple"
                            (containerClicked)="currentNode$.next($event.uuid)"
                            (selectedChange)="selected = $event"
                            [selectable]="selectableFn"></mesh-node-browser-list>

    <pagination-controls *ngIf="(pageCount$ | async) > 1"
                         (pageChange)="pageChanged($event)"
                         id="nodebrowser"></pagination-controls>
</div>
<div class="modal-footer">
    <gtx-button (click)="cancelFn()"
                flat
                type="secondary">{{ 'common.cancel_button' | i18n }}</gtx-button>
    <gtx-button (click)="submitClicked()"
                type="primary"
                [disabled]="!closableFn()">
        <span>{{ (options.submitLabelKey || 'common.choose') | i18n }}</span>
    </gtx-button>
</div>
