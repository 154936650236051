import * as tslib_1 from "tslib";
import { CloneDepth, Immutable, StateActionBranch } from 'immutablets';
import { TagFamilyResponse, TagResponse } from '../../common/models/server-models';
import { mergeEntityState } from './entity-state-actions';
let TagsStateActions = class TagsStateActions extends StateActionBranch {
    constructor() {
        super({
            uses: ['entities', 'tags'],
            initialState: {
                tags: {
                    tagFamilies: [],
                    tags: [],
                    loadCount: 0
                }
            }
        });
    }
    // TODO: remove the getNestedEntity reference to this.entities (in entities.service.ts)
    // Remove all external reference to this.entities.tag/tagFamily. The outside world should only care about state.tags.tag/tagFamilies
    // clearAll can be safely removed afterwards
    clearAll() {
        this.entities = mergeEntityState(this.entities, {
            tagFamily: [],
            tag: [],
        }, false);
        this.tags.tagFamilies = [];
        this.tags.tags = [];
    }
    loadTagFamiliesAndTheirTagsStart() {
        this.tags.loadCount++;
    }
    fetchTagFamiliesSuccess(fetchedFamilies) {
        this.tags.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            tagFamily: [
                ...fetchedFamilies
            ]
        }, false);
        this.tags.tagFamilies = Array.from(new Set([...this.tags.tagFamilies, ...fetchedFamilies.map(family => family.uuid)]));
    }
    loadTagFamiliesAndTheirTagsError() {
        this.tags.loadCount--;
    }
    fetchTagsOfTagFamilyStart() {
        this.tags.loadCount++;
    }
    fetchTagsOfTagFamilySuccess(fetchedTags) {
        this.tags.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            tag: [
                ...fetchedTags
            ]
        }, false);
        this.tags.tags = Array.from(new Set([...this.tags.tags, ...fetchedTags.map(tag => tag.uuid)]));
    }
    fetchTagsOfTagFamilyError() {
        this.tags.loadCount--;
    }
    createTagStart() {
        this.tags.loadCount++;
    }
    createTagSuccess(tag) {
        this.tags.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            tag: [tag]
        }, false);
        this.tags.tags = [...this.tags.tags, tag.uuid];
    }
    createTagError() {
        this.tags.loadCount--;
    }
    updateTagStart() {
        this.tags.loadCount++;
    }
    updateTagSuccess(tag) {
        this.tags.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            tag: [tag]
        }, false);
    }
    updateTagError() {
        this.tags.loadCount--;
    }
    deleteTagStart() {
        this.tags.loadCount++;
    }
    deleteTagSuccess(tagUuid) {
        this.tags.loadCount--;
        this.tags.tags = this.tags.tags.filter(uuid => uuid !== tagUuid);
    }
    deleteTagError() {
        this.tags.loadCount--;
    }
    createTagFamilyStart() {
        this.tags.loadCount++;
    }
    createTagFamilySuccess(tagFamily) {
        this.tags.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            tagFamily: [tagFamily]
        }, false);
        this.tags.tagFamilies = [...this.tags.tagFamilies, tagFamily.uuid];
    }
    createTagFamilyError() {
        this.tags.loadCount--;
    }
    updateTagFamilyStart() {
        this.tags.loadCount++;
    }
    updateTagFamilySuccess(tagFamily) {
        this.tags.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            tagFamily: [tagFamily]
        }, false);
    }
    updateTagFamilyError() {
        this.tags.loadCount--;
    }
    deleteTagFamilyStart() {
        this.tags.loadCount++;
    }
    deleteTagFamilySuccess(tagFamilyUuid) {
        this.tags.loadCount--;
        this.tags.tagFamilies = this.tags.tagFamilies.filter(uuid => uuid !== tagFamilyUuid);
    }
    deleteTagFamilyError() {
        this.tags.loadCount--;
    }
};
tslib_1.__decorate([
    CloneDepth(0),
    tslib_1.__metadata("design:type", Object)
], TagsStateActions.prototype, "entities", void 0);
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], TagsStateActions.prototype, "tags", void 0);
TagsStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [])
], TagsStateActions);
export { TagsStateActions };
