<div class="search-chip" [class.removable]="removable" [class.editable]="editable">
    <div class="chip-contents"><ng-content></ng-content></div>
    <icon class="remove-button"
          *ngIf="removable"
          [title]="removeTitle"
          (click)="removeClick.emit()">close</icon>
    <icon class="edit-button"
          *ngIf="editable"
          [title]="editTitle"
          (click)="editClick.emit()">edit</icon>
</div>
