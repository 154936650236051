import * as tslib_1 from "tslib";
import { CloneDepth, Immutable, StateActionBranch } from 'immutablets';
import { mergeEntityState } from './entity-state-actions';
let AuthStateActions = class AuthStateActions extends StateActionBranch {
    constructor() {
        super({
            uses: ['auth', 'entities'],
            initialState: {
                auth: {
                    changingPassword: false,
                    loggedIn: false,
                    loggingIn: false,
                    loggingOut: false,
                    currentUser: null
                }
            }
        });
    }
    changePasswordStart() {
        this.auth.changingPassword = true;
    }
    changePasswordSuccess() {
        this.auth.changingPassword = false;
    }
    changePasswordError() {
        this.auth.changingPassword = false;
    }
    loginStart() {
        this.auth.loggingIn = true;
    }
    loginSuccess(user) {
        // TODO: Save user session data (if there is any to save)
        this.auth.loggedIn = true;
        this.auth.loggingIn = false;
        this.auth.currentUser = user.uuid;
        this.entities = mergeEntityState(this.entities, {
            user: [user]
        });
    }
    loginError() {
        this.auth.loggedIn = false;
        this.auth.loggingIn = false;
    }
    logoutStart() {
        this.auth.loggingOut = true;
    }
    logoutSuccess() {
        this.auth.currentUser = null;
        this.auth.loggedIn = false;
        this.auth.loggingOut = false;
    }
    logoutError() {
        this.auth.loggingOut = false;
    }
};
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], AuthStateActions.prototype, "auth", void 0);
tslib_1.__decorate([
    CloneDepth(0),
    tslib_1.__metadata("design:type", Object)
], AuthStateActions.prototype, "entities", void 0);
AuthStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [])
], AuthStateActions);
export { AuthStateActions };
