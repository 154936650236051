import { forwardRef, Directive } from '@angular/core';
import { DateTimePickerStrings } from '@bewatec-berlin/gentics-ui-core';
import { DateTimePickerFormatProvider } from '@bewatec-berlin/gentics-ui-core';

@Directive({
    selector: '[meshDeFormatProvider]',
    providers: [
        {
            provide: DateTimePickerFormatProvider,
            useExisting: forwardRef(() => DeFormatProviderDirective)
        }
    ]
})
export class DeFormatProviderDirective extends DateTimePickerFormatProvider {
    strings: DateTimePickerStrings = {
        hours: 'Stunde',
        minutes: 'Minute',
        seconds: 'Sekunde',
        months: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember'
        ],
        monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        cancel: 'Abbrechen',
        okay: 'Okay'
    };
}
