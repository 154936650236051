import * as tslib_1 from "tslib";
import { CloneDepth, Immutable, StateActionBranch } from 'immutablets';
import { mergeEntityState } from './entity-state-actions';
let AdminUsersStateActions = class AdminUsersStateActions extends StateActionBranch {
    constructor() {
        super({
            uses: ['adminUsers', 'entities'],
            initialState: {
                adminUsers: {
                    loadCount: 0,
                    userList: [],
                    userDetail: null,
                    pagination: {
                        currentPage: 1,
                        itemsPerPage: 25,
                        totalItems: null
                    },
                    filterTerm: '',
                    filterGroups: []
                }
            }
        });
    }
    fetchUsersStart() {
        this.adminUsers.loadCount++;
    }
    fetchUsersSuccess(response) {
        const users = response.data;
        const metaInfo = response._metainfo;
        this.adminUsers.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            user: users
        });
        this.adminUsers.userList = users.map(user => user.uuid);
        this.adminUsers.pagination = {
            currentPage: metaInfo.currentPage,
            itemsPerPage: metaInfo.perPage,
            totalItems: metaInfo.totalCount
        };
    }
    fetchUsersError() {
        this.adminUsers.loadCount--;
    }
    fetchAllGroupsStart() {
        this.adminUsers.loadCount++;
    }
    fetchAllGroupsSuccess(groups) {
        this.adminUsers.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            group: groups
        });
    }
    fetchAllGroupsError() {
        this.adminUsers.loadCount--;
    }
    /**
     * To be used only when client-side paging strategy is used.
     */
    setUserListPagination(currentPage, itemsPerPage) {
        this.adminUsers.pagination = {
            currentPage,
            itemsPerPage,
            totalItems: null
        };
    }
    setFilterTerm(term) {
        this.adminUsers.filterTerm = term;
    }
    setFilterGroups(groups) {
        this.adminUsers.filterGroups = groups;
    }
    newUser() {
        this.adminUsers.userDetail = null;
    }
    openUserStart() {
        this.adminUsers.loadCount++;
        this.adminUsers.userDetail = null;
    }
    openUserSuccess(user, userNode, userNodeSchema, microschemas) {
        this.adminUsers.loadCount--;
        this.adminUsers.userDetail = user.uuid;
        const changes = {
            user: [user]
        };
        if (userNode) {
            changes.node = [userNode];
        }
        if (userNodeSchema) {
            changes.schema = [userNodeSchema];
        }
        if (microschemas) {
            changes.microschema = microschemas;
        }
        this.entities = mergeEntityState(this.entities, changes);
    }
    openUserError() {
        this.adminUsers.loadCount--;
    }
    createUserStart() {
        this.adminUsers.loadCount++;
    }
    createUserSuccess(user) {
        this.adminUsers.loadCount--;
        this.entities = mergeEntityState(this.entities, { user: [user] });
        this.adminUsers.userList = [...this.adminUsers.userList, user.uuid];
    }
    createUserError() {
        this.adminUsers.loadCount--;
    }
    updateUserStart() {
        this.adminUsers.loadCount++;
    }
    updateUserSuccess(user) {
        this.adminUsers.loadCount--;
        this.entities = mergeEntityState(this.entities, { user: [user] });
    }
    updateUserError() {
        this.adminUsers.loadCount--;
    }
    deleteUserStart() {
        this.adminUsers.loadCount++;
    }
    deleteUserSuccess(userUuid) {
        this.adminUsers.loadCount--;
        this.adminUsers.userList = this.adminUsers.userList.filter(uuid => uuid !== userUuid);
    }
    deleteUserError() {
        this.adminUsers.loadCount--;
    }
    addUserToGroupStart() {
        this.adminUsers.loadCount++;
    }
    addUserToGroupSuccess(user) {
        this.adminUsers.loadCount--;
        this.entities = mergeEntityState(this.entities, { user: [user] });
    }
    addUserToGroupError() {
        this.adminUsers.loadCount--;
    }
    removeUserFromGroupStart() {
        this.adminUsers.loadCount++;
    }
    removeUserFromGroupSuccess(user) {
        this.adminUsers.loadCount--;
        this.entities = mergeEntityState(this.entities, { user: [user] });
    }
    removeUserFromGroupError() {
        this.adminUsers.loadCount--;
    }
};
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], AdminUsersStateActions.prototype, "adminUsers", void 0);
tslib_1.__decorate([
    CloneDepth(0),
    tslib_1.__metadata("design:type", Object)
], AdminUsersStateActions.prototype, "entities", void 0);
AdminUsersStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [])
], AdminUsersStateActions);
export { AdminUsersStateActions };
