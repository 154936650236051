import * as tslib_1 from "tslib";
import { AfterViewChecked, ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { ListEffectsService } from 'src/app/core/providers/effects/list-effects.service';
import * as NodeUtil from '../../../common/util/node-util';
import { EditorEffectsService } from '../../../editor/providers/editor-effects.service';
import { EMeshNodeStatusStrings } from '../node-status/node-status.component';
export class PublishAllOptionsComponent {
    constructor(listEffects, editorEffects, changeDetectionRef) {
        this.listEffects = listEffects;
        this.editorEffects = editorEffects;
        this.changeDetectionRef = changeDetectionRef;
        this._publishInformationNeedsToBeRefreshed = false;
        this._contentChanged = false;
        this._status = 'loading';
        // all nodes of current language
        this._nodesCurrentLanguage = [];
        // all nodes
        this._nodes = [];
        this._projectName = null;
        this._containerUuid = null;
        this._language = null;
        this._nodeStatusesToBeConsidered = [];
        this.contentUpdated = new EventEmitter();
        this.nodeStatuses = Object.values(EMeshNodeStatusStrings);
        this.nodeUtil = NodeUtil;
    }
    set projectName(value) {
        this._projectName = value;
        this.tryToLoadPublishInformationForAllChildren();
    }
    set containerUuid(value) {
        this._containerUuid = value;
        this.tryToLoadPublishInformationForAllChildren();
    }
    set language(value) {
        this._language = value;
        this.tryToLoadPublishInformationForAllChildren();
    }
    set nodeStatusesToBeConsidered(value) {
        this._nodeStatusesToBeConsidered = value;
        this.tryToLoadPublishInformationForAllChildren();
    }
    ngOnInit() { }
    ngAfterViewChecked() {
        if (this._contentChanged) {
            this._contentChanged = false;
            this.contentUpdated.emit();
        }
    }
    publishAllNodes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.beforePublish) {
                yield this.beforePublish();
            }
            this.editorEffects.publishNodes(this._nodes.filter((node) => {
                return !this.nodeUtil.allLanguagesPublished(node);
            }));
        });
    }
    publishAllNodesLanguage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.beforePublish) {
                yield this.beforePublish();
            }
            this.editorEffects.publishNodesLanguage(this._nodesCurrentLanguage.filter((node) => {
                return !this.nodeUtil.currentLanguagePublished(node);
            }));
        });
    }
    unpublishAllNodes() { }
    unpublishAllNodesLanguage() { }
    markPublishInformationForRefresh() {
        this._status = 'loading';
        this._publishInformationNeedsToBeRefreshed = true;
        this._contentChanged = true;
        this.changeDetectionRef.markForCheck();
        this.tryToLoadPublishInformationForAllChildren();
    }
    tryToLoadPublishInformationForAllChildren() {
        // keep variables available for the time after async call completed
        const language = this._language;
        const nodeStatusesToBeConsidered = this._nodeStatusesToBeConsidered;
        if (this._publishInformationNeedsToBeRefreshed &&
            !!this._projectName &&
            !!this._containerUuid &&
            !!language &&
            !!nodeStatusesToBeConsidered) {
            this._publishInformationNeedsToBeRefreshed = false;
            this.listEffects
                .loadPublishInformationForAllChildren(this._projectName, this._containerUuid, language)
                .then((responseData) => {
                const nodesFilteredByStatus = responseData.data.filter((node) => {
                    const nodeStatus = this.nodeUtil.getNodeStatus(node, language)[0];
                    if (!!nodeStatus) {
                        return nodeStatusesToBeConsidered.includes(nodeStatus);
                    }
                    /**
                     * Fallback like in container-contents.component.html. This allows also considering child nodes
                     * that do not have a translation for the current language, in case all statuses (i.e. 'all') should be considered.
                     */
                    return nodeStatusesToBeConsidered.length >= this.nodeStatuses.length;
                });
                this._nodesCurrentLanguage = nodesFilteredByStatus.filter((node) => node.language === language);
                this._nodes = nodesFilteredByStatus;
                this._status = 'loaded';
                this._contentChanged = true;
                this.changeDetectionRef.markForCheck();
            }, () => {
                this._status = 'error';
                this._contentChanged = true;
                this.changeDetectionRef.markForCheck();
            });
        }
    }
}
