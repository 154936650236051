import * as tslib_1 from "tslib";
import { deepApplyWithReuse, Immutable, StateActionBranch } from 'immutablets';
let EntityStateActions = class EntityStateActions extends StateActionBranch {
    constructor() {
        super({
            uses: 'entities',
            initialState: {
                entities: {
                    group: {},
                    project: {},
                    node: {},
                    user: {},
                    schema: {},
                    microschema: {},
                    tag: {},
                    tagFamily: {}
                }
            }
        });
    }
};
EntityStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [])
], EntityStateActions);
export { EntityStateActions };
const defaultDiscriminator = ['uuid'];
const schemaDiscriminator = ['uuid', 'version'];
const microschemaDiscriminator = ['uuid', 'version'];
const nodeDiscriminator = ['uuid', 'language', 'version'];
/**
 * Utility function to update the entity state.
 *
 * Returns a new entity state with the passed changes applied.
 * Reuses references of objects whenever possible.
 * Arrays are not merged, and only reuse references of their elements when they are equal.
 *
 * When `strict` is set to false, then the entities in the `changes` object only need to include a uuid and
 * any missing parts of the discriminator will be guessed.
 */
export function mergeEntityState(oldState, changes, strict = true) {
    const newState = Object.assign({}, oldState);
    let anyBranchChanged = false;
    for (const key of Object.keys(changes)) {
        const oldBranch = oldState[key];
        const branchChanges = changes[key];
        if (!oldBranch) {
            throw new Error(`mergeEntityState: Trying to merge nonexisting entity key "${key}"`);
        }
        const discriminator = getDiscriminator(key);
        const branch = mergeBranch(oldBranch, branchChanges, discriminator, strict);
        if (branch !== oldBranch) {
            newState[key] = branch;
            anyBranchChanged = true;
        }
    }
    return anyBranchChanged ? newState : oldState;
}
/**
 * Merge an entity branch with a set of changes.
 * Reuses references of unchanged objects.
 */
function mergeBranch(oldBranch, changes, discriminator, strict = true) {
    let newBranch = Object.assign({}, oldBranch);
    let anyEntityChanged = false;
    for (const change of changes) {
        if (strict) {
            const missing = missingProperties(change, discriminator);
            if (0 < missing.length) {
                const missingString = missing.join(', ');
                const changeString = JSON.stringify(change, null, 4);
                throw new Error(`mergeBranch: Required discriminator properties not found: ${missingString} on ${changeString}`);
            }
        }
        if (!change.uuid) {
            throw new Error(`mergeBranch: Missing uuid on ${JSON.stringify(change, null, 4)}`);
        }
        const oldEntity = getNestedEntity(oldBranch, discriminator, change);
        const newEntity = oldEntity ? deepApplyWithReuse(oldEntity, change) : change;
        if (newEntity !== oldEntity) {
            newBranch = assignNestedEntity(newBranch, discriminator, newEntity);
            anyEntityChanged = true;
        }
    }
    return anyEntityChanged ? newBranch : oldBranch;
}
/**
 * Given a key, returns the discriminator array for that type.
 */
export function getDiscriminator(type) {
    switch (type) {
        case 'node':
            return nodeDiscriminator;
        case 'schema':
            return schemaDiscriminator;
        case 'microschema':
            return microschemaDiscriminator;
        default:
            return defaultDiscriminator;
    }
}
/**
 * Retrieves a deeply-nested value from the branch, the value being located at the object path
 * defined by the discriminator array.
 */
export function getNestedEntity(branch, discriminator, source, languageFallbacks) {
    // for all entities, the uuid is required
    if (!source || !source.uuid) {
        return undefined;
    }
    let o = branch;
    for (const k of discriminator) {
        let key = source[k];
        if (k === 'language' && Array.isArray(languageFallbacks) && 0 < languageFallbacks.length) {
            const availableLanguages = Object.keys(o);
            if (getLanguageKeyFromFallbackArray(availableLanguages, languageFallbacks)) {
                key = getLanguageKeyFromFallbackArray(availableLanguages, languageFallbacks);
            }
            else {
                return;
            }
        }
        else if (key === undefined) {
            // The discriminator part was not provided in the source.
            // We must choose a default from any available.
            const alternativeKeys = Object.keys(o);
            if (k === 'version') {
                // return the most recent version
                key = alternativeKeys.sort((a, b) => parseFloat(b) - parseFloat(a))[0];
            }
        }
        o = o[key];
        if (typeof o !== 'object') {
            return o;
        }
    }
    return o;
}
function getLanguageKeyFromFallbackArray(available, fallbacks) {
    for (const language of fallbacks) {
        if (-1 < available.indexOf(language)) {
            return language;
        }
    }
}
/**
 * Assigns the newValue to a deeply-nested property of the branch object. The nesting is defined by the
 * properties in the discriminator array.
 *
 * Example:
 * ```
 * const discriminator = ['uuid', 'language'];
 * const branch = {};
 * const entity = { uuid: 'abc', language: 'en', name: 'Foo' };
 *
 * assignNestedEntity(branch, discriminator, entity);
 * // => entity {
 * //       abc: {
 * //           en: { uuid: 'abc', language: 'en', name: 'Foo' }
 * //       }
 * //   };
 * ```
 */
function assignNestedEntity(branch, discriminator, newValue) {
    const mutateBranch = Object.assign({}, branch);
    let o = mutateBranch;
    for (const k of discriminator) {
        const key = newValue[k];
        o[key] = k === discriminator[discriminator.length - 1] ? newValue : {};
        o = o[key];
    }
    return deepApplyWithReuse(branch, mutateBranch);
}
/**
 * Given an object and an array of required property names, returns an array of those
 * required properties which are not present on the object.
 */
function missingProperties(object, required) {
    const missing = [];
    for (const prop of required) {
        if (!object.hasOwnProperty(prop)) {
            missing.push(prop);
        }
    }
    return missing;
}
