import { of as observableOf } from 'rxjs';
const ɵ0 = (hash, name) => {
    hash[name] = require(`./translations_json/${name}.translations.json`);
    return hash;
};
const translationFiles = ['admin', 'auth', 'common', 'editor', 'lang', 'list', 'modal', 'nodebrowser'].reduce(ɵ0, {});
// Parse the yaml files into a JS object.
const translations = Object.assign({}, translationFiles);
/**
 * A custom language loader which splits apart a translations object in the format:
 * {
 *   SECTION: {
 *     TOKEN: {
 *       lang1: "...",
 *       lang2: "....
 *     }
 *   }
 * }
 */
export class CustomLoader {
    getTranslation(lang) {
        const output = {};
        for (const section in translations) {
            if (translations.hasOwnProperty(section)) {
                output[section] = {};
                for (const token in translations[section]) {
                    if (translations[section].hasOwnProperty(token)) {
                        output[section][token] = translations[section][token][lang];
                    }
                }
            }
        }
        return observableOf(output);
    }
}
export { ɵ0 };
