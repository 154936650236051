<gtx-top-bar *ngIf="loggedIn$ | async">
    <div class="gtx-top-bar-left">
        <a class="logo-container"
           routerLink="/">
            <img src={{bewatecConfig?.connectedCareLogo}}
                 alt="Bewatec">
            <span>
                <span class="color-navy">Content Management</span>
            </span>
        </a>
    </div>

    <div class="gtx-top-bar-right">
        <gtx-button size="regular"
                    flat
                    type="secondary"
                    *ngIf="!(adminMode$ | async)"
                    [routerLink]="['/admin']">Admin</gtx-button>
        <gtx-button size="regular"
                    flat
                    type="secondary"
                    *ngIf="adminMode$ | async"
                    [routerLink]="['/editor', 'project']">Content</gtx-button>
        <gtx-side-menu-toggle>
            <gtx-button *ngIf="!displayMenu"
                        size="regular"
                        type="primary"
                        [icon]="true"
                        (click)="displayMenu = !displayMenu">User
                <icon right>account_circle</icon>
            </gtx-button>
        </gtx-side-menu-toggle>
    </div>
</gtx-top-bar>

<gtx-side-menu [opened]="displayMenu"
               (toggle)="displayMenu = $event"
               position="right">
    <gtx-side-menu-toggle>
        <gtx-button *ngIf="displayMenu"
                    size="regular"
                    type="primary"
                    [icon]="true">User
            <icon right>account_circle</icon>
        </gtx-button>
    </gtx-side-menu-toggle>

    <div class="menu-content">
        <ul>
            <li>
                <mesh-language-switcher></mesh-language-switcher>
            </li>
            <mesh-user-dropdown></mesh-user-dropdown>
        </ul>
    </div>
</gtx-side-menu>

<router-outlet></router-outlet>
<gtx-overlay-host></gtx-overlay-host>
