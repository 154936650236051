import { enableProdMode } from '@angular/core';
// TODO: switch to lettable operators (https://github.com/ReactiveX/rxjs/blob/master/doc/lettable-operators.md)
// so as to not import the entire RXJS bundle thereby decreasing bundle size, allowing tree-shaking.
// tslint:disable-next-line:import-blacklist
import 'rxjs/Rx';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, 
// Enable preservation of whitespaces for default spacing between components.
{ preserveWhitespaces: true })
    .catch(err => console.log(err));
