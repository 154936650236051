import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService, Notification } from '@bewatec-berlin/gentics-ui-core';
import { combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as ScheduleUtil from 'src/app/common/util/schedule-util';
import { I18nService } from 'src/app/core/providers/i18n/i18n.service';
import { ADDED_INTERVAL_IN_MINUTES, ADDED_START_INTERVAL_IN_MINUTES } from '../../../common/constants';
import { ConfigService } from '../../../core/providers/config/config.service';
import { EditorEffectsService } from '../../providers/editor-effects.service';
import { ConfirmUnpublishedModalComponent } from '../confirm-unpublished-modal/confirm-unpublished-modal.component';
export class SchedulingModalComponent {
    constructor(editorEffects, notification, i18n, config, modalService) {
        this.editorEffects = editorEffects;
        this.notification = notification;
        this.i18n = i18n;
        this.config = config;
        this.modalService = modalService;
        this.START_INTERVAL = ADDED_START_INTERVAL_IN_MINUTES * 60 * 1000;
        this.END_INTERVAL = ADDED_INTERVAL_IN_MINUTES * 60 * 1000;
        this.isNodePublished = false;
        this.start$ = new BehaviorSubject(null);
        this.end$ = new BehaviorSubject(null);
        this.destroy$ = new Subject();
        this.scheduleUtil = ScheduleUtil;
        this.validationObj = { message: 'default', conflict: true };
        this.validationConstraint = undefined;
        this.closeFn = (val) => { };
        this.cancelFn = (val) => { };
        this.dateValidator = control => {
            if (this.validationObj.conflict) {
                return this.validationObj;
            }
            return null;
        };
        this.validStart = control => {
            if (control.value) {
                this.start$.next(control.value);
            }
            else {
                this.start$.next(0);
            }
            return null;
        };
        this.validEnd = control => {
            if (control.value) {
                this.end$.next(control.value);
            }
            else {
                this.end$.next(0);
            }
            return null;
        };
    }
    ngOnInit() {
        this.localLanguage = this.i18n.getSelectedLanguage();
        this.translateToGerman = this.localLanguage === this.config.DEFAULT_LANGUAGE;
        this.defaultStartDateTime = Date.now() + this.START_INTERVAL;
        this.defaultEndDateTime = this.defaultStartDateTime + this.END_INTERVAL;
        try {
            const lang = this.node.language;
            if (lang) {
                this.isNodePublished = this.node.availableLanguages[lang].published;
            }
        }
        catch (e) {
            this.isNodePublished = false;
        }
        const startDate = new FormControl({ value: this.startDateTimestamp || '', disabled: this.isNodePublished }, Validators.compose([Validators.min(Math.floor(this.defaultStartDateTime / 1000)), this.validStart]));
        const endDate = new FormControl({ value: this.endDateTimestamp || '', disabled: false }, Validators.compose([Validators.min(Math.floor(this.defaultEndDateTime / 1000)), this.validEnd]));
        this.form = new FormGroup({
            startDate: startDate,
            endDate: endDate
        });
        combineLatest([this.start$, this.end$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(response => {
            const startDT = response[0] ? response[0] * 1000 : null;
            const endDT = response[1] ? response[1] * 1000 : null;
            if (startDT) {
                this.defaultStartDateTime = startDT;
                this.defaultEndDateTime = startDT + this.END_INTERVAL;
            }
            this.validationObj = { message: 'checking', conflict: false };
            this.validationConstraint = undefined;
            if (this.isNodePublished) {
                if (startDT) {
                    this.validationConstraint = 'isPublished';
                    this.validationObj = {
                        conflict: true,
                        message: 'scheduled start date not allowed for a published node'
                    };
                }
                if (!endDT) {
                    this.validationConstraint = 'requireEndDate';
                    this.validationObj = {
                        conflict: true,
                        message: 'scheduled end date should exist'
                    };
                }
            }
            else {
                if (!startDT) {
                    this.validationConstraint = 'requireStartDate';
                    this.validationObj = { conflict: true, message: 'scheduled start date should exist' };
                }
                if (endDT && startDT && endDT < startDT + this.END_INTERVAL) {
                    this.validationConstraint = 'requireLaterEndDate';
                    this.validationObj = {
                        conflict: true,
                        message: 'scheduled end date should be grater then scheduled start date'
                    };
                }
            }
        });
        this.form.setValidators(Validators.compose([this.dateValidator]));
    }
    registerCloseFn(close) {
        this.destroy$.next();
        this.destroy$.complete();
        this.closeFn = close;
    }
    registerCancelFn(cancel) {
        this.destroy$.next();
        this.destroy$.complete();
        this.cancelFn = cancel;
    }
    schedulePublication() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.node.container || !this.end$.getValue()) {
                this.actionPublish(this.node);
                this.closeFn();
            }
            else {
                const options = {
                    closeOnOverlayClick: false
                };
                this.modalService
                    .fromComponent(ConfirmUnpublishedModalComponent, options, { node: this.node, willBeScheduled: true })
                    .then(modal => modal.open())
                    .then(resp => {
                    if (resp) {
                        this.actionPublish(this.node);
                        this.closeFn();
                    }
                });
            }
        });
    }
    actionPublish(node) {
        try {
            this.scheduleUtil.setNodeUpdateSchemaValue(node, this.form);
            this.editorEffects.saveNode(node).then(() => {
                if (this.isNodePublished && parseInt(node.version.split('.')[1], 10) === 0) {
                    this.editorEffects.publishNode(node);
                }
            });
        }
        catch (err) {
            this.notification.show({
                type: 'error',
                message: err.toString()
            });
        }
    }
}
