import * as tslib_1 from "tslib";
import { OnChanges, OnInit } from '@angular/core';
import { ModalService } from '@bewatec-berlin/gentics-ui-core';
import { SchedulingModalComponent } from 'src/app/editor/components/scheduling-modal/scheduling-modal.component';
import { ADDED_INTERVAL_IN_MINUTES, ADDED_START_INTERVAL_IN_MINUTES } from '../../../common/constants';
import * as NodeUtil from '../../../common/util/node-util';
import * as ScheduleUtil from '../../../common/util/schedule-util';
import { ConfigService } from '../../../core/providers/config/config.service';
import { EditorEffectsService } from '../../../editor/providers/editor-effects.service';
import { EMeshNodeStatusStrings } from '../node-status/node-status.component';
export class PublishScheduledOptionsComponent {
    constructor(editorEffects, modalService, config) {
        this.editorEffects = editorEffects;
        this.modalService = modalService;
        this.config = config;
        this.nodeUtil = NodeUtil;
        this.scheduleUtil = ScheduleUtil;
        this.isUpdated = false;
    }
    ngOnInit() {
        this.checkStatus();
    }
    ngOnChanges() {
        this.checkStatus();
    }
    openSchedulingModal(startDateTimestamp, endDateTimestamp) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const isPublished = this.nodeUtil.currentLanguagePublished(this.node);
            if (isPublished || this.isUpdated) {
                if (endDateTimestamp === undefined) {
                    endDateTimestamp = this.getTimestamp(ADDED_START_INTERVAL_IN_MINUTES + ADDED_INTERVAL_IN_MINUTES);
                }
            }
            else if (startDateTimestamp === undefined) {
                startDateTimestamp = this.getTimestamp(ADDED_START_INTERVAL_IN_MINUTES);
            }
            this.modalService
                .fromComponent(SchedulingModalComponent, {}, { node: this.node, startDateTimestamp, endDateTimestamp })
                .then(modal => modal.open());
        });
    }
    getTimestamp(intervalInMinutes = 0) {
        return Math.floor((Date.now() + intervalInMinutes * 60 * 1000) / 1000);
    }
    openSchedulingModalDefault() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const now = new Date();
            this.openSchedulingModal(this.scheduleUtil.getNextMondayTimestamp(now));
        });
    }
    openSchedulingModalEdit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { startDate, endDate } = JSON.parse(this.node.fields.nodeUpdateSchedule);
            this.openSchedulingModal(startDate, endDate);
        });
    }
    clearSchedule() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.scheduleUtil.clearNodeUpdateSchemaValue(this.node);
            const isPublished = this.nodeUtil.currentLanguagePublished(this.node);
            this.editorEffects.saveNode(this.node).then(() => {
                if (isPublished) {
                    this.editorEffects.publishNode(this.node);
                }
            });
        });
    }
    checkStatus() {
        this.status = this.nodeUtil.getNodeStatus(this.node, this.node.language || this.config.DEFAULT_LANGUAGE);
        this.isUpdated = Array.from(this.status).includes(EMeshNodeStatusStrings.UPDATED);
    }
}
