import { FormGroup } from '@angular/forms';

import { UILanguage } from '../../core/providers/i18n/i18n.service';
import { MeshNode } from '../models/node.model';

/**
 * Utils for scheduling publication
 */
const getNextMondayTimestamp = (date: Date): number => {
    date.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7 || 7));
    date.setHours(8);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.getTime() / 1000;
};

const setNodeUpdateSchemaValue = (node: MeshNode, form: FormGroup): void => {
    node.fields.nodeUpdateSchedule = JSON.stringify({
        startDate: form.controls.startDate.value ? form.controls.startDate.value.toString() : '',
        endDate: form.controls.endDate.value ? form.controls.endDate.value.toString() : '',
        language: node.language
    });
};

const clearNodeUpdateSchemaValue = (node: MeshNode): void => {
    node.fields.nodeUpdateSchedule = null;
};

const getScheduleDate = (json: string, value: 'startDate' | 'endDate') => {
    try {
        const readObj = JSON.parse(json);
        const date = readObj[value];
        if (!date) {
            return;
        }
        return new Date(parseInt(date, 10) * 1000);
    } catch {
        return;
    }
};

const formatDate = (lang: UILanguage, date?: Date) => {
    if (!date) {
        return '';
    }
    return new Intl.DateTimeFormat(lang, { formatMatcher: 'basic' }).format(date);
};

const formatTime = (lang: UILanguage, date?: Date) => {
    if (!date) {
        return '';
    }
    // @ts-ignore Argument of type '{ timeStyle: string; }' is not assignable to parameter of type 'DateTimeFormatOptions'.
    return new Intl.DateTimeFormat(lang, { timeStyle: 'short' }).format(date);
};

export {
    getNextMondayTimestamp,
    setNodeUpdateSchemaValue,
    clearNodeUpdateSchemaValue,
    getScheduleDate,
    formatDate,
    formatTime
};
