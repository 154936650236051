import * as tslib_1 from "tslib";
import { CloneDepth, Immutable, StateActionBranch } from 'immutablets';
import { ConfigService } from '../../core/providers/config/config.service';
import { mergeEntityState } from './entity-state-actions';
let ListStateActions = class ListStateActions extends StateActionBranch {
    constructor(config) {
        super({
            uses: ['entities', 'list'],
            initialState: {
                list: {
                    currentNode: undefined,
                    currentProject: undefined,
                    loadCount: 0,
                    language: config.FALLBACK_LANGUAGE,
                    items: [],
                    filterTerm: ''
                }
            }
        });
        this.config = config;
    }
    fetchChildrenStart() {
        this.list.loadCount++;
    }
    // TODO: think about how we will handle language variants of containers.
    // For now we will rely on the "best guess" default behaviour of
    // the getNestedEntity() function within mergeEntityState()
    fetchChildrenSuccess(containerUuid, children) {
        this.list.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            node: children
        }, false);
        this.list.items = children.map(node => node.uuid);
    }
    fetchChildrenError() {
        this.list.loadCount--;
    }
    fetchPublishInformationForChildrenStart() {
        this.list.loadCount++;
    }
    fetchPublishInformationForChildrenSuccess() {
        this.list.loadCount--;
    }
    fetchPublishInformationForChildrenError() {
        this.list.loadCount--;
    }
    fetchNodeStart() {
        this.list.loadCount++;
    }
    fetchNodeSuccess(node) {
        this.list.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            node: [node]
        }, false);
    }
    fetchNodeError() {
        this.list.loadCount--;
    }
    fetchProjectsStart() {
        this.list.loadCount++;
    }
    fetchProjectsSuccess(projects) {
        this.list.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            project: projects
        });
    }
    fetchProjectsError() {
        this.list.loadCount--;
    }
    fetchMicroschemasStart() {
        this.list.loadCount++;
    }
    fetchMicroschemasSuccess(projectName, microschemas) {
        const projectUuid = Object.keys(this.entities.project).filter(uuid => this.entities.project[uuid].name === projectName)[0];
        this.list.loadCount--;
        // save in entity root
        this.entities = mergeEntityState(this.entities, {
            microschema: microschemas
        });
        // save in projects property
        this.entities = mergeEntityState(this.entities, {
            project: [
                {
                    uuid: projectUuid,
                    microschemas: microschemas.map(microschema => ({
                        name: microschema.name,
                        uuid: microschema.uuid,
                        version: microschema.version
                    }))
                }
            ],
            microschema: microschemas
        });
    }
    fetchMicroschemasError() {
        this.list.loadCount--;
    }
    fetchMicroschemaStart() {
        this.list.loadCount++;
    }
    fetchMicroschemaSuccess(microschema) {
        this.list.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            microschema: [microschema]
        });
    }
    fetchMicroschemaError() {
        this.list.loadCount--;
    }
    fetchSchemasStart(projectName) {
        this.list.loadCount++;
    }
    fetchSchemasSuccess(projectName, schemas) {
        const projectUuid = Object.keys(this.entities.project).filter(uuid => this.entities.project[uuid].name === projectName)[0];
        this.list.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            project: [
                {
                    uuid: projectUuid,
                    schemas: schemas.map(schema => ({
                        name: schema.name,
                        uuid: schema.uuid,
                        version: schema.version
                    }))
                }
            ],
            schema: schemas
        });
    }
    fetchSchemasError() {
        this.list.loadCount--;
    }
    fetchSchemaStart() {
        this.list.loadCount++;
    }
    fetchSchemaSuccess(schema) {
        this.list.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            schema: [schema]
        });
    }
    fetchSchemaError() {
        this.list.loadCount--;
    }
    deleteNodeStart() {
        this.list.loadCount++;
    }
    deleteNodeError() {
        this.list.loadCount--;
    }
    deleteNodeSuccess() {
        this.list.loadCount--;
    }
    moveNodeStart() {
        this.list.loadCount++;
    }
    moveNodeSuccess() {
        this.list.loadCount--;
    }
    moveNodeError() {
        this.list.loadCount--;
    }
    copyNodeStart() {
        this.list.loadCount++;
    }
    copyNodeSuccess() {
        this.list.loadCount--;
    }
    copyNodeError() {
        this.list.loadCount--;
    }
    searchNodesStart() {
        this.list.loadCount++;
    }
    searchNodesSuccess(items) {
        this.list.loadCount--;
        this.entities = mergeEntityState(this.entities, {
            node: items
        }, false);
        this.list.items = items.map(node => node.uuid);
    }
    searchNodesError(response) {
        this.list.loadCount--;
    }
    /** Change the active container in the list view from values of the current route. */
    setActiveContainer(projectName, containerUuid, language) {
        this.list.currentProject = projectName;
        this.list.currentNode = containerUuid;
        this.list.language = language;
    }
    /** Sets the filter term for the nodes. */
    setFilterTerm(term) {
        this.list.filterTerm = term;
    }
};
tslib_1.__decorate([
    CloneDepth(0),
    tslib_1.__metadata("design:type", Object)
], ListStateActions.prototype, "entities", void 0);
tslib_1.__decorate([
    CloneDepth(1),
    tslib_1.__metadata("design:type", Object)
], ListStateActions.prototype, "list", void 0);
ListStateActions = tslib_1.__decorate([
    Immutable(),
    tslib_1.__metadata("design:paramtypes", [ConfigService])
], ListStateActions);
export { ListStateActions };
